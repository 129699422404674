import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import QRCode from "qrcode.react";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  leftAlignedText: {
    textAlign: "left"
  }
};

const useStyles = makeStyles(styles);

export default function BankAccounts() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              FOR SINGAPORE BANK ACCOUNT HOLDERS
            </h4>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText="Bank"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "United Overseas Bank Limited (UOB)"
              }}
            />
            <CustomInput
              labelText="Account Name"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "Sindo Ferry Pte Ltd"
              }}
            />
            <CustomInput
              labelText="Account No"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "450-302-696-6 (SGD)"
              }}
            />
            <CustomInput
              labelText="Bank Code"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "7375"
              }}
            />
            <CustomInput
              labelText="Branch Code"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "001"
              }}
            />
            <CustomInput
              labelText="Swift Code"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "UOVBSGSG"
              }}
            />

            <Card pricing>
              <CardBody pricing>
                <h3 className={classes.cardCategory}>
                  <b>We accept payment via PayNow.</b>
                </h3>
                <div>
                  <QRCode
                    size={256}
                    value="00020101021126400009SG.PAYNOW010120213199902667HHFC030115204000053037025802SG5919SINDO FERRY PTE LTD6009Singapore6304798B"
                  />
                </div>
                <ol className={classes.leftAlignedText}>
                  <li>
                    Launch your Bank Mobile application and select <i>PayNow</i>
                    .
                  </li>
                  <li>Login with your username and password.</li>
                  <li>
                    Select to pay with QR Code or pay to Business/UEN Our UEN is{" "}
                    <b>199902667H</b>.
                  </li>
                  <li>Enter the amount.</li>
                  <li>
                    Enter your reference such as your Agent Name or Agent code
                    for easy identification.
                  </li>
                  <li>
                    Check and make sure you have entered the amount and
                    reference correctly.
                  </li>
                  <li>Click Send or Pay.</li>
                  <li>Inform us that you have made a payment.</li>
                  <li>
                    If successful, you will receive sms or email notification
                    from the Bank.
                  </li>
                </ol>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              FOR INDONESIA BANK ACCOUNT HOLDERS
            </h4>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText="Bank"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "PT. Bank UOB Indonesia"
              }}
            />
            <CustomInput
              labelText="Account Name"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "Sindo Ferry Pte Ltd"
              }}
            />
            <CustomInput
              labelText="Account No (IDR)"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "326-300-927-2 (IDR)"
              }}
            />
            <CustomInput
              labelText="Account No (SGD)"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "326-901-587-8 (SGD)"
              }}
            />
            <CustomInput
              labelText="Swift Code"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: "BBIJIDJA"
              }}
            />
            <CustomInput
              labelText=""
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: ""
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
