export function GetGenders() {
  return [
    {
      key: 0,
      description: "Male"
    },
    {
      key: 1,
      description: "Female"
    }
  ];
}

export function GetGenderDescription(value) {
  let gender = GetGenders().find(item => item.key === value);
  return gender.description;
}
