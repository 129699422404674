import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import InstagramIcon from "@material-ui/icons/Instagram";
import Menu from "@material-ui/icons/Menu";
import FacebookIcon from "@material-ui/icons/Facebook";
import LanguageIcon from "@material-ui/icons/Language";

// core components
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  var list = (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          to="sindoferry"
          target="_blank"
          onClick={event => {
            event.preventDefault();
            window.open("https://www.sindoferry.com.sg");
          }}
        >
          <LanguageIcon className={classes.listItemIcon} />
          <ListItemText
            primary={"sindoferry.com.sg"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          to="Refer & Get Paid"
          target="_blank"
          onClick={event => {
            event.preventDefault();
            window.open("https://bit.ly/SindoHeroForm");
          }}
        >
          <LoyaltyIcon className={classes.listItemIcon} />
          <ListItemText
            primary={"Refer & Get Paid"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          to="instagram"
          target="_blank"
          onClick={event => {
            event.preventDefault();
            window.open("https://www.instagram.com/sindoferry");
          }}
        >
          <InstagramIcon className={classes.listItemIcon} />
          <ListItemText
            primary={"instagram"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          to="facebook"
          target="_blank"
          onClick={event => {
            event.preventDefault();
            window.open("https://www.facebook.com/sindoferrysg");
          }}
        >
          <FacebookIcon className={classes.listItemIcon} />
          <ListItemText
            primary={"facebook"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </Link>
      </ListItem>
    </List>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
              {brandText}
            </Button>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
              MD Pro React
            </Button>
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={open}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};
