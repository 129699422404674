import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";

import Detail from "views/BookingCancellation/Detail";
import PendingList from "views/BookingCancellation/PendingList";
import SubmittedList from "views/BookingCancellation/SubmittedList";
import ApprovedList from "views/BookingCancellation/ApprovedList";
import RejectedList from "views/BookingCancellation/RejectedList";
import Create from "views/BookingCancellation/Create";
import Submit from "views/BookingCancellation/Submit";
import Delete from "views/BookingCancellation/Delete";

import { token } from "functions/Helper";

const bookingCancellation = () => {
  let views = [];

  if (
    token["booking.booking_cancellations.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/BookingCancellations/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingList,
        layout: "/admin"
      },
      {
        path: "/BookingCancellations/Submitted",
        name: "Submitted",
        mini: "SU",
        component: SubmittedList,
        layout: "/admin"
      },
      {
        path: "/BookingCancellations/Approved",
        name: "Approved",
        mini: "AP",
        component: ApprovedList,
        layout: "/admin"
      },
      {
        path: "/BookingCancellations/Rejected",
        name: "Rejected",
        mini: "RE",
        component: RejectedList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Booking Cancellations",
        icon: AssignmentLateIcon,
        state: "bookingCancellationCollapse",
        views: views
      }
    ];
  return [];
};

const bookingCancellationHidden = () => {
  let views = [];
  if (
    token["booking.booking_cancellations.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/BookingCancellation/Detail",
      name: "Booking Cancellation Detail",
      component: Detail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.booking_cancellations.submit"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/BookingCancellation/Submit",
      name: "Booking Cancellation Submit",
      component: Submit,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.booking_cancellations.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/BookingCancellation/Create",
        name: "Create Booking Cancellation",
        component: Create,
        layout: "/admin"
      },
      {
        path: "/BookingCancellation/Delete",
        name: "Delete Booking Cancellation",
        component: Delete,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Booking Cancellation Hidden",
        state: "bookingCancellationHiddenCollapse",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { bookingCancellation, bookingCancellationHidden };
