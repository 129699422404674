import NotificationsIcon from "@material-ui/icons/Notifications";

import NotificationListList from "views/Notification/NotificationList/NotificationListList";
import CreateNotificationList from "views/Notification/NotificationList/CreateNotificationList";
import DeleteNotificationList from "views/Notification/NotificationList/DeleteNotificationList";
import NotificationListDetail from "views/Notification/NotificationList/NotificationListDetail";

import NotificationConfigList from "views/Notification/NotificationConfig/NotificationConfigList";
import CreateNotificationConfig from "views/Notification/NotificationConfig/CreateNotificationConfig";
import DeleteNotificationConfig from "views/Notification/NotificationConfig/DeleteNotificationConfig";
import NotificationConfigDetail from "views/Notification/NotificationConfig/NotificationConfigDetail";

import { token } from "functions/Helper";

const notificationList = () => {
  let views = [];
  if (
    token["agent.notification_lists.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/NotificationListList",
      name: "Notification Lists",
      mini: "NL",
      component: NotificationListList,
      layout: "/admin"
    });
  if (
    token["agent.notification_configs.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/NotificationConfigList",
      name: "Notification Configs",
      mini: "NC",
      component: NotificationConfigList,
      layout: "/admin"
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Notification",
        icon: NotificationsIcon,
        state: "notificationCollapse",
        views: views
      }
    ];
  return [];
};

const notificationListHidden = () => {
  let views = [];
  if (
    token["agent.notification_lists.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/NotificationLists/Create",
        name: "Create NotificationList",
        component: CreateNotificationList,
        layout: "/admin"
      },
      {
        path: "/NotificationLists/Delete",
        name: "Delete Notification List",
        component: DeleteNotificationList,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["agent.notification_lists.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/NotificationLists/Detail",
      name: "Notification List Detail",
      component: NotificationListDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "NotificationList Hidden",
        state: "notificationListHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

const notificationConfigHidden = () => {
  let views = [];
  if (
    token["agent.notification_configs.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/NotificationConfigs/Create",
        name: "Create NotificationConfig",
        component: CreateNotificationConfig,
        layout: "/admin"
      },
      {
        path: "/NotificationConfigs/Delete",
        name: "Delete Notification Config",
        component: DeleteNotificationConfig,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["agent.notification_configs.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/NotificationConfigs/Detail",
      name: "Notification Config Detail",
      component: NotificationConfigDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "NotificationConfig Hidden",
        state: "notificationConfigHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { notificationList, notificationListHidden, notificationConfigHidden };
