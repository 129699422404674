import React from "react";
import PropTypes from "prop-types";

import CustomInput from "components/CustomInput/CustomInput.js";

import { formatCurrency } from "functions/Helper";

export default function ViewTopUp(props) {
  const { topUp } = props;

  return (
    <>
      <CustomInput
        disabled
        labelText="No"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: topUp.no
        }}
      />
      <CustomInput
        disabled
        labelText="Amount"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: formatCurrency(
            topUp.currency === null ? "XXX" : topUp.currency.code,
            topUp.balance
          )
        }}
      />
      <CustomInput
        disabled
        labelText="Note"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: topUp.note
        }}
      />
    </>
  );
}

ViewTopUp.propTypes = {
  topUp: PropTypes.object
};
