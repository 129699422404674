import React from "react";

import { Switch, Route } from "react-router-dom";
import routes from "routes.js";
export default function Empty() {
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/empty") {
        let params = "";
        if (prop.params) {
          prop.params.forEach(item => {
            params += "/:" + item.name;
          });
        }
        return (
          <Route
            path={prop.layout + prop.path + params}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  return <Switch>{getRoutes(routes)}</Switch>;
}
