export function GetOrderCancellationStatuses() {
  return [
    {
      key: 0,
      description: "None"
    },
    {
      key: 1,
      description: "Requested"
    },
    {
      key: 2,
      description: "Approved"
    },
    {
      key: 3,
      description: "Rejected"
    }
  ];
}

export function GetOrderCancellationStatusDescription(value) {
  let orderCancellationStatus = GetOrderCancellationStatuses().find(
    item => item.key === value
  );
  return orderCancellationStatus.description;
}
