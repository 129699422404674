import React, { useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishSharpIcon from "@material-ui/icons/PublishSharp";
import RemoveIcon from "@material-ui/icons/Remove";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { creditMonitoringApi } from "functions/apis";

import { formatDateTime, formatCurrency, token } from "functions/Helper";
import { GetTopUpMethodDescription } from "enums/TopUpMethod";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function PendingTopUpList() {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null,
    status: 0,
    sort: 3
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    creditMonitoringApi
      .get("/TopUps", {
        signal: abortSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              method: GetTopUpMethodDescription(record.method),
              paymentReceiptFile: record.hasPaymentReceiptFile ? (
                <div className="actions-right">
                  <Button
                    color="info"
                    className="like"
                    onClick={() =>
                      axios
                        .get(
                          process.env.REACT_APP_CREDIT_MONITORING_API_URL +
                            "/TopUps/PaymentReceipt/" +
                            record.id,
                          {
                            responseType: "arraybuffer",
                            headers: {
                              Authorization: localStorage.getItem(
                                "Authorization"
                              )
                            }
                          }
                        )
                        .then(response => {
                          let filename = response.request
                            .getResponseHeader("Content-Disposition")
                            .split(";")[2]
                            .replace("filename*=UTF-8''", "");
                          const url = window.URL.createObjectURL(
                            new Blob([response.data])
                          );
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute("download", filename);
                          document.body.appendChild(link);
                          link.click();
                        })
                    }
                  >
                    Download
                  </Button>
                  <Link to={"/admin/TopUps/RemovePaymentReceipt/" + record.id}>
                    <Button color="danger" className="like">
                      <RemoveIcon /> Remove Receipt
                    </Button>
                  </Link>
                </div>
              ) : (
                <div>No File Attached</div>
              ),
              actions: (
                <div className="actions-right">
                  <>
                    <Link to={"/admin/TopUps/Detail/" + record.id}>
                      <Button color="info" className="like">
                        <VisibilityIcon /> View
                      </Button>
                    </Link>
                    {record.method === 0 && (
                      <Link
                        to={"/admin/TopUps/UploadPaymentReceipt/" + record.id}
                      >
                        <Button color="info" className="like">
                          <PublishSharpIcon /> Upload Receipt
                        </Button>
                      </Link>
                    )}
                    {record.status === 0 && (
                      <Link to={"/admin/TopUps/Delete/" + record.id}>
                        <Button color="danger" className="remove">
                          <DeleteIcon /> DELETE
                        </Button>
                      </Link>
                    )}
                  </>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>TOP UP - PENDING</h4>
          </CardHeader>
          <CardBody>
            {(token["creditmonitoring.top_ups.write"] === "allowed" ||
              token.is_admin === "True") && (
              <Link to={"/admin/TopUps/Create"}>
                <Button color="rose" type="button" value="Create">
                  New Top Up
                </Button>
              </Link>
            )}
            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleSearchStringChanged,
                  value: filter.searchString === null ? "" : filter.searchString
                }}
              />
            </GridItem>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "No",
                  accessor: "no"
                },
                {
                  id: "balance",
                  Header: "Balance",
                  accessor: d =>
                    formatCurrency(
                      d.currency === null ? "XXX" : d.currency.code,
                      d.balance
                    )
                },
                {
                  Header: "Note",
                  accessor: "note"
                },
                {
                  id: "createdAt",
                  Header: "Created At",
                  accessor: d => formatDateTime(new Date(d.createdAt + "Z"))
                },
                {
                  Header: "Created By",
                  accessor: "createdBy"
                },
                {
                  Header: "Method",
                  accessor: "method"
                },
                {
                  Header: "Payment Receipt File",
                  accessor: "paymentReceiptFile",
                  sortable: false,
                  filterable: false
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
