import AirlineSeatReclineNormalIcon from "@material-ui/icons/AirlineSeatReclineNormal";

import BookingTypeList from "views/BookingType/BookingTypeList.js";
import BookingTypeDetail from "views/BookingType/BookingTypeDetail.js";

import { token } from "functions/Helper";

const bookingType = () => {
  let views = [];
  if (
    token["order.booking_type_pricing.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/BookingTypes",
      name: "Booking Types",
      icon: AirlineSeatReclineNormalIcon,
      component: BookingTypeList,
      layout: "/admin"
    });
  if (views.length > 0) return views;
  return [];
};

const bookingTypeHidden = () => {
  let views = [];
  if (
    token["order.booking_type_pricing.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/BookingTypeDetail",
      name: "Booking Type",
      component: BookingTypeDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "BookingType Hidden",
        state: "bookingTypeHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { bookingType, bookingTypeHidden };
