import React from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Button from "components/CustomButtons/Button.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ImportExcel() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              IMPORT PASSENGER DATA FROM EXCEL
            </h4>
          </CardHeader>
          <CardBody>
            <Button
              color="info"
              className="like"
              onClick={() =>
                axios
                  .get("/files/import-passenger-example.xlsx", {
                    responseType: "arraybuffer",
                    contentType:
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  })
                  .then(response => {
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "import-passenger-example.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                  })
              }
            >
              Download Example
            </Button>
            <Button
              color="info"
              className="like"
              onClick={() =>
                axios
                  .get("/files/import-passenger-guidelines.xlsx", {
                    responseType: "arraybuffer",
                    contentType:
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  })
                  .then(response => {
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "import-passenger-guidelines.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                  })
              }
            >
              Download Guidelines
            </Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
