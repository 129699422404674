import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import ViewVoucherType from "./ViewVoucherType";
import { orderApi } from "functions/apis";

export default function VoucherTypeDetail() {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  let { id } = useParams();

  const [voucherType, setVoucherType] = React.useState(null);
  useEffect(() => {
    orderApi
      .get("/VoucherTypes/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => setVoucherType(data))
      .catch(error => console.log(error));
  }, [id]);

  return voucherType !== null && <ViewVoucherType voucherType={voucherType} />;
}
