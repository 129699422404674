import React from "react";

export default function GetNotification(notification) {
  let data = JSON.parse(notification.data);
  switch (notification.type) {
    case "creditmonitoring.top_up_approved":
      return {
        content: (
          <>
            Top up #<b>{data.No}</b> has been <b>Approved</b>.<br />
          </>
        ),
        link: "/admin/TopUps/Detail/" + data.ID
      };
    case "creditmonitoring.top_up_rejected":
      return {
        content: (
          <>
            Top up #<b>{data.No}</b> has been <b>Rejected</b>.<br />
          </>
        ),
        link: "/admin/TopUps/Detail/" + data.ID
      };
    case "creditmonitoring.low_balance_alert":
      return {
        content: (
          <>
            Balance is Low. Please consider to Top Up your balance.
            <br />
          </>
        ),
        link: "/admin/MyAgent"
      };
    case "order.order_cancellation_request_approved":
      return {
        content: (
          <>
            Cancellation for Order #<b>{data.No}</b> has been <b>Approved</b>.
            <br />
          </>
        ),
        link: "/admin/OrderCancellations/Detail/" + data.ID
      };
    case "order.order_cancellation_request_rejected":
      return {
        content: (
          <>
            Cancellation for Order #<b>{data.No}</b> has been <b>Rejected</b>.
            <br />
          </>
        ),
        link: "/admin/OrderCancellations/Detail/" + data.ID
      };
    case "booking.agent_booking_cancellation_request_approved":
      return {
        content: (
          <>
            Cancellation for Booking #<b>{data.ConfirmationCode}</b> with No #
            {data.No} has been <b>Approved</b>.
            <br />
          </>
        ),
        link: "/admin/BookingCancellations/Detail/" + data.ID
      };
    case "booking.agent_booking_cancellation_request_rejected":
      return {
        content: (
          <>
            Cancellation for Booking #<b>{data.ConfirmationCode}</b> with No #
            {data.No} has been <b>Rejected</b>.
            <br />
          </>
        ),
        link: "/admin/BookingCancellations/Detail/" + data.ID
      };
    case "creditmonitoring.commission_created":
      return {
        content: (
          <>
            Commission with no #<b>{data.No}</b> has been <b>created</b>.
            <br />
          </>
        ),
        link: "/admin/Commissions/Detail/" + data.ID
      };
    default:
      return null;
  }
}
