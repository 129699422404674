export function GetBookingTransferStatuses() {
  return [
    {
      key: 0,
      description: "Pending"
    },
    {
      key: 1,
      description: "Submitted"
    },
    {
      key: 2,
      description: "Approved"
    },
    {
      key: 3,
      description: "Rejected"
    }
  ];
}

export function GetBookingTransferStatusDescription(value) {
  let bookingTransferStatus = GetBookingTransferStatuses().find(
    item => item.key === value
  );
  return bookingTransferStatus.description;
}
