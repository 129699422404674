import HelpIcon from "@material-ui/icons/Help";

import BankAccounts from "views/Help/BankAccounts.js";
import ContactUs from "views/Help/ContactUs.js";
import ImportExcel from "views/Help/ImportExcel.js";

const help = () => {
  let views = [
    {
      path: "/BankAccounts",
      name: "Bank Accounts",
      mini: "BA",
      component: BankAccounts,
      layout: "/admin"
    },
    {
      path: "/ContactUs",
      name: "Contact Us",
      mini: "CU",
      component: ContactUs,
      layout: "/admin"
    },
    {
      path: "/ImportExcel",
      name: "Import Excel",
      mini: "IE",
      component: ImportExcel,
      layout: "/admin"
    }
  ];
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Help",
        icon: HelpIcon,
        state: "helpCollapse",
        views: views
      }
    ];
  return [];
};

export { help };
