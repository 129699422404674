import ReceiptIcon from "@material-ui/icons/Receipt";

import CommissionList from "views/Commission/CommissionList.js";
import CommissionDetail from "views/Commission/CommissionDetail.js";

import { token } from "functions/Helper";

const commission = () => {
  let views = [];
  if (
    token["creditmonitoring.commissions.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/Commissions/List",
        name: "Earned Commission",
        icon: ReceiptIcon,
        component: CommissionList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0) return views;
  return [];
};

const commissionHidden = () => {
  let views = [];
  if (
    token["creditmonitoring.commissions.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/Commissions/Detail",
        name: "Commission Detail",
        component: CommissionDetail,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Commission Hidden",
        state: "commissionHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { commission, commissionHidden };
