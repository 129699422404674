import axios from "axios";

function getHeaders() {
  let headers = {};
  if (localStorage.getItem("Authorization") !== null)
    headers = {
      ...headers,
      Authorization: localStorage.getItem("Authorization")
    };
  return headers;
}

const coreApi = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_URL
});

const masterApi = axios.create({
  baseURL: process.env.REACT_APP_MASTER_API_URL,
  headers: getHeaders()
});

const agentApi = axios.create({
  baseURL: process.env.REACT_APP_AGENT_API_URL,
  headers: getHeaders()
});

const bookingApi = axios.create({
  baseURL: process.env.REACT_APP_BOOKING_API_URL,
  headers: getHeaders()
});

const creditMonitoringApi = axios.create({
  baseURL: process.env.REACT_APP_CREDIT_MONITORING_API_URL,
  headers: getHeaders()
});

const orderApi = axios.create({
  baseURL: process.env.REACT_APP_ORDER_API_URL,
  headers: getHeaders()
});

const responseInterceptor = response => {
  return response.data.data;
};

const errorResponseHandler = error => {
  if (
    error.message !== "canceled" &&
    error.message !== "cancelled" &&
    error !== "canceled"
  ) {
    if (error.response !== undefined) {
      if (error.response.status === 401) return Promise.reject(error);

      if (error.response.data !== undefined)
        alert(JSON.stringify(error.response.data.exception.message));
      else alert(JSON.stringify(error.response));
    } else {
      alert(JSON.stringify(error));
    }
  }
  return Promise.reject(error);
};

masterApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
agentApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
bookingApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
creditMonitoringApi.interceptors.response.use(
  responseInterceptor,
  errorResponseHandler
);
orderApi.interceptors.response.use(responseInterceptor, errorResponseHandler);

export {
  coreApi,
  masterApi,
  agentApi,
  bookingApi,
  creditMonitoringApi,
  orderApi
};
