export function GetOrderStatuses() {
  return [
    {
      key: 0,
      description: "Pending"
    },
    {
      key: 1,
      description: "Payment In Process"
    },
    {
      key: 2,
      description: "Paid"
    },
    {
      key: 3,
      description: "Payment Failed"
    },
    {
      key: 4,
      description: "Voucher Issued"
    }
  ];
}

export function GetOrderStatusDescription(value) {
  let orderStatus = GetOrderStatuses().find(item => item.key === value);
  return orderStatus.description;
}
