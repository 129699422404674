export function GetBalanceHistorySortOptions() {
  return [
    {
      key: 0,
      description: "Transaction Time ASC"
    },
    {
      key: 1,
      description: "Transaction Time DESC"
    }
  ];
}

export function GetBalanceHistorySortOptionDescription(value) {
  let balanceHistorySortOption = GetBalanceHistorySortOptions().find(
    item => item.key === value
  );
  return balanceHistorySortOption.description;
}
