import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";

import Detail from "views/BookingTransfer/Detail";
import PendingList from "views/BookingTransfer/PendingList";
import SubmittedList from "views/BookingTransfer/SubmittedList";
import Create from "views/BookingTransfer/Create";
import Submit from "views/BookingTransfer/Submit";
import Delete from "views/BookingTransfer/Delete";

import { token } from "functions/Helper";
import RejectedList from "views/BookingTransfer/RejectedList";
import ApprovedList from "views/BookingTransfer/ApprovedList";

const bookingTransfer = () => {
  let views = [];

  if (
    token["booking.booking_transfers.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/BookingTransfers/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingList,
        layout: "/admin"
      },
      {
        path: "/BookingTransfers/Submitted",
        name: "Submitted",
        mini: "SU",
        component: SubmittedList,
        layout: "/admin"
      },
      {
        path: "/BookingTransfers/Approved",
        name: "Approved",
        mini: "AP",
        component: ApprovedList,
        layout: "/admin"
      },
      {
        path: "/BookingTransfers/Rejected",
        name: "Rejected",
        mini: "RJ",
        component: RejectedList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Booking Transfers",
        icon: AssignmentLateIcon,
        state: "bookingTransferCollapse",
        views: views
      }
    ];
  return [];
};

const bookingTransferHidden = () => {
  let views = [];
  if (
    token["booking.booking_transfers.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/BookingTransfer/Detail",
      name: "Booking Transfer Detail",
      component: Detail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.booking_transfers.submit"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/BookingTransfer/Submit",
      name: "Booking Transfer Submit",
      component: Submit,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.booking_transfers.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/BookingTransfer/Create",
        name: "Create Booking Transfer",
        component: Create,
        layout: "/admin"
      },
      {
        path: "/BookingTransfer/Delete",
        name: "Delete Booking Transfer",
        component: Delete,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Booking Transfer Hidden",
        state: "bookingTransferHiddenCollapse",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { bookingTransfer, bookingTransferHidden };
