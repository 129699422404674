import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { creditMonitoringApi } from "functions/apis";
import { formatDateTime, token } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function CommissionDetail(props) {
  const abortSignal = useRef(new AbortController());
  const [agentCredit, setAgentCredit] = React.useState(null);

  useEffect(() => {
    if (
      token["creditmonitoring.agents.read"] === "allowed" ||
      token.is_admin === "True"
    )
      creditMonitoringApi
        .get("/Agents", {
          signal: abortSignal.current.signal
        })
        .then(data => setAgentCredit(data))
        .catch(error => console.log(error));
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [commission, setCommission] = React.useState(null);

  useEffect(() => {
    creditMonitoringApi
      .get("/Commissions/" + id, {
        signal: abortSignal.current.signal
      })
      .then(res => setCommission(res))
      .catch(error => console.log(error));
  }, [id]);

  const classes = useStyles();
  return (
    commission !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Commission</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <CustomInput
                labelText="No"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: commission.no
                }}
              />
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Amount"
                    id="numAmount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agentCredit === null ||
                        agentCredit.billingCurrency === null
                          ? ""
                          : agentCredit.billingCurrency.code +
                            " " +
                            commission.amount.toLocaleString()
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={commission.hasGST}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has GST"
                  />
                </GridItem>
              </GridContainer>
              <CustomInput
                labelText="Note"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: commission.note === null ? "" : commission.note
                }}
              />
              <CustomInput
                labelText="Created At"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: formatDateTime(new Date(commission.createdAt + "Z"))
                }}
              />
              <Button
                color="info"
                className="like"
                onClick={() =>
                  axios
                    .get(
                      process.env.REACT_APP_CREDIT_MONITORING_API_URL +
                        `/Commissions/${commission.id}/PaymentAdvice`,
                      {
                        responseType: "arraybuffer",
                        headers: {
                          Authorization: localStorage.getItem("Authorization")
                        }
                      }
                    )
                    .then(response => {
                      let filename = response.request
                        .getResponseHeader("Content-Disposition")
                        .split(";")[2]
                        .replace("filename*=UTF-8''", "");
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", filename);
                      document.body.appendChild(link);
                      link.click();
                    })
                }
              >
                Download Payment Advice
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

CommissionDetail.propTypes = {
  history: PropTypes.object
};
