import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { agentApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function EditUser(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [user, setUser] = React.useState(null);
  useEffect(() => {
    agentApi
      .get("/Users/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => setUser(data))
      .catch(error => console.log(error));
  }, [id]);

  const handleEmailChanged = e =>
    setUser({ ...user, email: e.target.value === "" ? null : e.target.value });

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    agentApi
      .put("/Users/" + id, user, {
        signal: abortSignal.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Users/Detail/" + id} />
  ) : (
    user !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>EDIT USER</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={handleSubmit}>
                <CustomInput
                  labelText="UserName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: user.userName === null ? "" : user.userName
                  }}
                />
                <CustomInput
                  labelText="Email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleEmailChanged,
                    value: user.email === null ? "" : user.email
                  }}
                />
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Update"
                >
                  Update
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

EditUser.propTypes = {
  history: PropTypes.object
};
