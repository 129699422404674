import AssessmentIcon from "@material-ui/icons/Assessment";

import Report from "views/Report/Index";

const report = () => {
  let views = [];
  views.push({
    path: "/Report",
    name: "Report",
    icon: AssessmentIcon,
    component: Report,
    layout: "/admin"
  });
  return views;
};

export { report };
