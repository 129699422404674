import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { orderApi } from "functions/apis";

import { formatDateTime, token } from "functions/Helper";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ConfirmedOrderList() {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [filter, setFilter] = React.useState({
    searchString: null,
    status: 4,
    sort: 11
  });
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState({});
  useEffect(() => {
    setLoading(true);
    orderApi
      .get("/Orders", {
        signal: abortSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              allowedIdentificationNos:
                record.allowedIdentificationNos === null
                  ? ""
                  : record.allowedIdentificationNos.join(";\n"),
              actions: (
                <div className="actions-right">
                  {record.isCancelled === false &&
                    (token["order.orders.issue_voucher"] === "allowed" ||
                      token.is_admin === "True") && (
                      <>
                        {!record.voucherType.hasIdentificationNoRestriction && (
                          <Button
                            color="info"
                            className="like"
                            onClick={() => {
                              window.open(
                                "/empty/Order/PrintVoucherIssuance/" + record.id
                              );
                              setTimeout(() => setReload({}), 3000);
                            }}
                          >
                            <PrintIcon /> PRINT
                          </Button>
                        )}
                        <Button
                          color="info"
                          className="like"
                          onClick={() =>
                            axios
                              .get(
                                process.env.REACT_APP_ORDER_API_URL +
                                  "/Orders/" +
                                  record.id +
                                  "/PrintOut" +
                                  (record.voucherType
                                    .hasIdentificationNoRestriction
                                    ? "SeasonPass"
                                    : ""),
                                {
                                  responseType: "arraybuffer",
                                  headers: {
                                    Authorization: localStorage.getItem(
                                      "Authorization"
                                    )
                                  }
                                }
                              )
                              .then(response => {
                                const url = window.URL.createObjectURL(
                                  new Blob([response.data])
                                );
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute(
                                  "download",
                                  record.no + ".pdf"
                                );
                                document.body.appendChild(link);
                                link.click();
                              })
                          }
                        >
                          DOWNLOAD
                        </Button>
                        <Link to={"/admin/Order/SendToEmail/" + record.id}>
                          <Button color="info" className="like">
                            <EmailIcon /> Send to email
                          </Button>
                        </Link>
                      </>
                    )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter, reload]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CONFIRMED ORDER</h4>
          </CardHeader>
          <CardBody>
            {((token["order.orders.process_payment"] === "allowed" &&
              token["order.orders.issue_voucher"] === "allowed") ||
              token.is_admin === "True") && (
              <Link to="/admin/Order/QuickCreate">
                <Button color="rose">Create</Button>
              </Link>
            )}
            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleSearchStringChanged,
                  value: filter.searchString === null ? "" : filter.searchString
                }}
              />
            </GridItem>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "No",
                  accessor: "no"
                },
                {
                  id: "voucherType",
                  Header: "Voucher Type",
                  accessor: d => d.voucherType.code
                },
                {
                  Header: "Quantity",
                  accessor: "quantity"
                },
                {
                  Header: "Remarks",
                  accessor: "remarks"
                },
                {
                  Header: "Allowed Identification Nos",
                  accessor: "allowedIdentificationNos"
                },
                {
                  id: "confirmedAt",
                  Header: "Confirmed At",
                  accessor: d => formatDateTime(new Date(d.confirmedAt + "Z"))
                },
                {
                  Header: "Confirmed By",
                  accessor: "confirmedBy"
                },
                {
                  Header: "Print Counter",
                  accessor: "printCounter"
                },
                {
                  id: "isCancelled",
                  Header: "Is Cancelled",
                  accessor: d => (d.isCancelled === true ? "Yes" : "No")
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
