import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons

// @material-ui/core components

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

export default function ViewAmount(props) {
  const { booking, currency } = props;
  return (
    <GridContainer>
      <GridItem xs={12} sm={3}>
        <CustomInput
          labelText="Currency"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: currency
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={3}>
        <CustomInput
          labelText="Total"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: booking.total.toLocaleString()
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={3}>
        <CustomInput
          labelText="Voucher Amount"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: booking.voucherAmount.toLocaleString()
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={3}>
        <CustomInput
          labelText="Outstanding Amount"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: booking.outstandingAmount.toLocaleString()
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

ViewAmount.propTypes = {
  booking: PropTypes.object,
  currency: PropTypes.string
};
