import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";

import ReactToPrint from "react-to-print";
import QRCode from "qrcode.react";

// @material-ui/icons
import PrintIcon from "@material-ui/icons/Print";

// core components
import Button from "components/CustomButtons/Button.js";

import { orderApi } from "functions/apis";

export default function PrintVoucherIssuance() {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const printComponent = useRef();
  const [order, setOrder] = React.useState(null);

  useEffect(() => {
    orderApi
      .get("/Orders/" + id + "/WithVoucherIssuance", {
        signal: abortSignal.current.signal
      })
      .then(data => setOrder(data))
      .catch(error => console.log(error));
  }, [id]);

  const createPrintContent = () => {
    let issuerInformation = JSON.parse(order.voucherIssuance.issuerInformation);
    let vouchers = [];
    let totalVouchers = order.voucherIssuance.vouchers.length;
    let currentRecord = 0;
    let currentPage = 0;
    for (let i = 0; i < totalVouchers; i++) {
      let voucher = order.voucherIssuance.vouchers[i];
      let totalTrips = voucher.voucherCodes.length;
      for (let j = 0; j < totalTrips; j++) {
        currentRecord++;
        if (currentRecord % 2 === 1 || j === 0) {
          currentPage++;
          vouchers.push(
            <div key={"page-header-" + currentRecord} className="page-header">
              <div>{currentPage}</div>
            </div>
          );
        }
        let vcode = voucher.voucherCodes[j];
        let issuanceTrip = order.voucherIssuance.trips.filter(
          issuanceTrip =>
            issuanceTrip.voucherTypeTripID === vcode.voucherTypeTrip.id
        )[0];
        let classes = ["trip", j % 2 === 0 ? "odd" : "even"];
        vouchers.push(
          <div key={vcode.id} className={classNames(classes)}>
            <div>
              <img src="/sindo-logo-color-sm.png" alt="logo" />
            </div>
            <div className="title">
              <p>
                <b>SINDO FERRY E-TICKET VOUCHER</b>
              </p>
              <p>
                <b>WWW.SINDOFERRY.COM.SG</b>
              </p>
            </div>
            <div>
              <h4 className={classes.cardIconTitle}>
                <b>{vcode.voucherTypeTrip.description}</b>
              </h4>
              <p>Terms and Conditions:</p>
              <ul className="restrictions">
                {order.voucherIssuance.voucherType.hasPaxTypeRestriction && (
                  <li>
                    Can only be used by{" "}
                    <b>
                      {order.voucherIssuance.voucherType.allowedPaxType.code}
                    </b>
                  </li>
                )}
                {order.voucherIssuance.voucherType
                  .hasNationalityRestriction && (
                  <li>
                    Can only be used by{" "}
                    <b>
                      {
                        order.voucherIssuance.voucherType.allowedNationality
                          .nationality
                      }
                    </b>
                  </li>
                )}
                {issuanceTrip.hasTripRestriction && (
                  <li>
                    Can only be used in on Route{" "}
                    <b>{issuanceTrip.route.code}</b> @
                    {moment(new Date(issuanceTrip.tripDate + "Z")).format(
                      "yyyy-MM-DD"
                    )}{" "}
                    - {issuanceTrip.scheduleCode}
                  </li>
                )}
                {vcode.voucherTypeTrip.hasDayGroupRestriction && (
                  <li>
                    Can only be used on{" "}
                    <b>{vcode.voucherTypeTrip.allowedDayGroup.name}</b>
                  </li>
                )}
                {vcode.voucherTypeTrip.hasSectorRestriction && (
                  <li>
                    Can only be used on sector{" "}
                    <b>{vcode.voucherTypeTrip.allowedSector.name}</b>
                  </li>
                )}
                {vcode.voucherTypeTrip.hasRouteRestriction && (
                  <li>
                    Can only be used in one of these Route(s):
                    <ul className="allowedRoutes">
                      {vcode.voucherTypeTrip.allowedRoutes.map(allowedRoute => {
                        return (
                          <li key={allowedRoute.route.code}>
                            |{allowedRoute.route.code}|
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                )}
              </ul>
              <div className="components">
                Component(s) included:
                <ul>
                  {order.voucherIssuance.voucherType.includeSurcharge && (
                    <li>| Surcharge |</li>
                  )}
                  {order.voucherIssuance.voucherType.includePDF && (
                    <li>| Singapore Terminal Fee |</li>
                  )}
                  {order.voucherIssuance.voucherType.includeTF && (
                    <li>| Batam Terminal Fee |</li>
                  )}
                  {order.voucherIssuance.voucherType.includeCF && (
                    <li>| Confirmation Fee |</li>
                  )}
                </ul>
              </div>
              <div className="remarks">
                {order.remarks !== null && <p>Remarks: {order.remarks}</p>}
              </div>
            </div>
            <div>
              <p>
                <QRCode value={vcode.id} />
              </p>
              <p>
                <b>No: {vcode.id.toUpperCase()}</b>
              </p>
              <p>
                Issued at{" "}
                <b>
                  {moment(
                    new Date(order.voucherIssuance.issueTime + "Z")
                  ).format("LLL")}
                </b>
              </p>
              <p>
                Expired at{" "}
                <b>
                  {moment(new Date(order.voucherIssuance.expiryDate + "Z"))
                    .add(-1, "d")
                    .format("ddd, MMM DD YYYY")}
                </b>
              </p>
              <p>
                Issued by <b>{issuerInformation.Agent.Name}</b>
              </p>
            </div>
          </div>
        );
        if (currentRecord % 2 === 0 && j + 1 < totalTrips)
          vouchers.push(
            <div
              key={"page-breaker" + currentRecord}
              className="page-breaker"
            ></div>
          );
      }
      if (totalTrips % 2 === 1)
        vouchers.push(
          <div key={"blank-" + currentRecord} className="trip blank"></div>
        );
      vouchers.push(
        <div key={"footer-" + currentRecord} className="footer">
          <p>
            <b>IMPORTANT INFORMATION: </b>
          </p>
          <ol>
            <li>
              Please ensure you have a valid passport with 6 months validity and
              other supporting documents (if required) such as entry and exit
              visa, health documents etc.
            </li>
            <li>
              Please check-in at Sindo Ferry counter and clear the Immigration
              at least 90 minutes before departure time.
            </li>
            <li>Immigration gate closes 30 minutes before departure time.</li>
            <li>
              If you have luggage to check in, please ensure the weight does not
              exceed 20 kg. You are not allowed to bring any liquid, dangerous
              and prohibited goods onboard.
            </li>
            <li>
              At any time, Sindo Ferry reserves the right to cancel, vary this
              service in any way, or substitute any ferry or other means of
              transport without incurring any liability and without giving any
              prior notice.
            </li>
            <li>
              If you wish to make a reservation / check-in, find out the
              departure location and the latest ferry schedules, please visit
              our website at <i>www.sindoferry.com.sg</i>.
            </li>
            <li>
              Please present either mobile or printed e-vouchers when making a
              reservation.
            </li>
            <li>
              For passengers departing from Tanjung Pinang and Tanjung Balai
              terminal: please pay your terminal fees directly to the local
              terminal operator. Kindly prepare an exact change.
            </li>
            <li>
              By using the vouchers, you are deemed to have accepted and agreed
              to be bound by Sindo Ferry’s terms and conditions at{" "}
              <i>www.sindoferry.com.sg/terms-and-condition</i>. Please visit the
              link for more information.
            </li>
          </ol>
          <p>
            <i>* No: {order.no}</i>
          </p>
          <img
            style={{
              width: "170mm",
              height: "25mm"
            }}
            alt="banner"
            src={"/sindo-explorer-banner.png"}
          />
        </div>
      );
      vouchers.push(
        <div
          key={"page-breaker" + currentRecord}
          className="page-breaker"
        ></div>
      );
    }
    return vouchers;
  };
  return (
    order === null || (
      <>
        <ReactToPrint
          trigger={() => (
            <Button color="rose" type="button" value="Create">
              <PrintIcon /> PRINT OUT
            </Button>
          )}
          content={() => printComponent.current}
          onAfterPrint={() => setOrder(null)}
        />
        <div
          style={{
            display: "none"
          }}
        >
          <div ref={printComponent}>
            <style>
              {`@media print{
                    .page-breaker{
                        page-break-after: always;
                    }
                    .page-header{
                        height: 14mm;
                        max-height: 14mm;
                        position: relative;
                        padding-left: 15mm;
                        padding-top: 5mm;
                        padding-right: 20mm;
                    }
                    .page-header div:first-child{
                        text-align: right;
                    }
                    .footer{
                        overflow: auto;
                        margin: auto;
                        width: 170mm;
                        max-width: 170mm;
                        font-size: x-small;
                        line-height:1.2;
                        padding: 3mm 0mm 0mm;
                    }
                    .pagination{
                        position:absolute;
                        top: 5mm;
                        right: 12mm;
                    }
                    .trip{
                        position: relative;
                        font-size: x-small;
                        line-height:1;
                        margin: auto;
                        width: 170mm;
                        max-width: 170mm;
                        height: 80mm;
                        max-height: 80mm;
                        padding: 2mm 2mm 10mm;
                    }
                    .odd{
                        border-top: 0.2mm solid;
                        border-left: 0.2mm solid;
                        border-right: 0.2mm solid;
                    }
                    .even{
                        border-top: 0.2mm dashed;
                        border-left: 0.2mm solid;
                        border-right: 0.2mm solid;
                        border-bottom: 0.2mm solid;
                    }
                    .blank{
                        border-top: 0.2mm solid;
                    }
                    .trip div{
                        float: left;
                        position:relative;
                    }
                    .components{
                        position: absolute;
                        top: 4mm;
                    }
                    .remarks{
                        position: absolute;
                        top: 5mm;
                        clear: both;
                    }
                    .trip div:first-child{
                        padding-top: 3mm;
                        width: 60mm;
                        max-width: 60mm;
                        height: 21mm;
                        max-height: 21mm;
                    }
                    .trip div:first-child img{
                        height: 15mm;
                        max-height: 15mm;
                        width: 30mm;
                        max-width: 30mm;
                    }
                    .title{
                        width: 100mm;
                        max-width: 100mm;
                        height: 21mm;
                        max-height: 21mm;
                        text-align: center;
                        color: rgb(12,92,153);
                        padding-top: 3mm;
                        line-height: 3mm;
                    }
                    .title p:first-child{
                        font-size: 20;
                    }
                    .title p:nth-child(2){
                        line-height: 1mm;
                    }
                    .trip div:nth-child(3){
                        width: 90mm;
                        max-width: 90mm;
                    }
                    .trip div:nth-child(4){
                        width: 70mm;
                        max-width: 70mm;
                    }
                    .components ul{
                        font-style: italic;
                        list-style-type: none;
                    }
                    .components ul li{
                        float: left;
                        padding: 2;
                        margin: 2;
                    }
                    .allowedRoutes{
                        font-style: italic;
                        font-weight: bold;
                        list-style-type: none;
                    }
                    .allowedRoutes li{
                        float: left;
                        padding: 2;
                        margin: 2;
                    }
                }`}
            </style>
            {createPrintContent()}
          </div>
        </div>
      </>
    )
  );
}
