import React, { useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { creditMonitoringApi } from "functions/apis";

import { GetIDRTopUpMethods, GetSGDTopUpMethods } from "enums/TopUpMethod";

const useStyles = makeStyles(styles);

export default function CreateTopUp(props) {
  const abortSignal = useRef(new AbortController());
  const [agentCredit, setAgentCredit] = React.useState(null);

  useEffect(() => {
    creditMonitoringApi
      .get("/Agents", {
        signal: abortSignal.current.signal
      })
      .then(data => setAgentCredit(data))
      .catch(error => console.log(error));
    return () => abortSignal.current.abort();
  }, []);

  const [balance, setBalance] = React.useState(0);
  const [note, setNote] = React.useState(null);
  const [method, setMethod] = React.useState(0);

  const [fee, setFee] = React.useState({
    fee: 0,
    total: 0
  });

  const calculateFee = (newMethod, newBalance) =>
    creditMonitoringApi
      .get(`/TopUps/GetFee?method=${newMethod}&topUpBalance=${newBalance}`, {
        signal: abortSignal.current.signal
      })
      .then(data =>
        setFee({
          fee: data,
          total: newBalance + data
        })
      )
      .catch(error => console.log(error));

  const handleNoteChanged = e =>
    setNote(e.target.value === "" ? null : e.target.value);

  const handleBalanceChanged = e => {
    let newBalance = parseFloat(e.target.value === "" ? 0 : e.target.value);
    setBalance(newBalance);
    calculateFee(method, newBalance);
  };

  const handleMethodChanged = e => {
    setMethod(e.target.value);
    calculateFee(e.target.value, balance);
  };

  const [redirect, setRedirect] = React.useState(false);

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    creditMonitoringApi
      .post(
        "TopUps",
        {
          balance: balance,
          method: method,
          note: note
        },
        {
          signal: abortSignal.current.signal
        }
      )
      .then(res => {
        if (method != 0) {
          creditMonitoringApi
            .get("/TopUps/" + res, {
              signal: abortSignal.current.signal
            })
            .then(res => {
              window.location.href =
                process.env.REACT_APP_PAYMENT_URL +
                `/payment/${res.paymentID}?callbackUrl=${process.env.REACT_APP_SITE_URL}/admin/TopUps/Detail/${res.id}`;
            })
            .catch(error => console.log(error));
        } else {
          setRedirect(true);
        }
      })
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/TopUps/Pending"} />
  ) : (
    agentCredit !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>CREATE TOP UP</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Billing Currency"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:
                          agentCredit.billingCurrency === null
                            ? ""
                            : agentCredit.billingCurrency.code
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Balance"
                      id="numBalance"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleBalanceChanged,
                        value: balance === 0 ? "" : balance
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Processing Fee"
                      id="numFee"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        disabled: true,
                        value: fee.fee
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Total"
                      id="numTotal"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        disabled: true,
                        value: fee.total
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <p>
                  <i>
                    *Our processing fees help us to cover our admin fees,
                    platform fees and some third party costs. If you&apos;d like
                    to avoid paying the fee, please go for PayNow or Bank
                    transfer (Manual).
                  </i>
                </p>
                <CustomInput
                  labelText="Note"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleNoteChanged,
                    value: note === null ? "" : note
                  }}
                />
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selTopUpMethod"
                    className={classes.selectLabel}
                  >
                    Method
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={method === null ? "" : method}
                    onChange={handleMethodChanged}
                  >
                    {agentCredit.billingCurrency.code === "IDR"
                      ? GetIDRTopUpMethods().map(method => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={method.key}
                              key={method.key}
                            >
                              <b>{method.description}</b>
                            </MenuItem>
                          );
                        })
                      : GetSGDTopUpMethods().map(method => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={method.key}
                              key={method.key}
                            >
                              <b>{method.description}</b>
                            </MenuItem>
                          );
                        })}
                  </Select>
                </FormControl>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

CreateTopUp.propTypes = {
  history: PropTypes.object
};
