import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import MailOutline from "@material-ui/icons/MailOutline";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { formatDateTime, getTimezoneLabel } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import moment from "moment";

const useStyles = makeStyles(styles);

export default function View(props) {
  const { booking, history } = props;
  const classes = useStyles();
  const departureTimezoneLabel = getTimezoneLabel(
    booking.departureCoreApiTrip.route.embarkationPort.scheduleTimeZone
  );
  const returnTimezoneLabel = getTimezoneLabel(
    booking.returnCoreApiTrip?.route.embarkationPort.scheduleTimeZone
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>BOOKING DETAILS</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => history.goBack()} value="Back">
              BACK
            </Button>
            {booking.status === 0 && (
              <Link to={"/admin/Booking/Edit/" + booking.id}>
                <Button color="info" className="remove">
                  <Edit /> EDIT
                </Button>
              </Link>
            )}
            <CustomInput
              labelText="Booking No"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: booking.no
              }}
            />
            {booking.status === 1 && (
              <>
                <CustomInput
                  labelText="Confirmation Code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: booking.confirmationCode
                  }}
                />
                <CustomInput
                  labelText="Latest Revision"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: booking.latestRevision
                  }}
                />
              </>
            )}
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  checked={booking.isRoundTrip}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label="Round Trip"
            />
            <CustomInput
              labelText="Departure Route"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: booking.departureCoreApiTrip.route.name
              }}
            />
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Departure Date"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: moment(booking.departureCoreApiTrip.date).format(
                      "dddd, MMMM Do YYYY"
                    )
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText={`Departure Time (${departureTimezoneLabel})`}
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: `${booking.departureCoreApiTrip.time.substring(
                      0,
                      2
                    )}:${booking.departureCoreApiTrip.time.substring(2, 4)}`
                  }}
                />
              </GridItem>
            </GridContainer>
            {booking.isRoundTrip && (
              <>
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      checked={booking.isReturnTripOpen}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Open Return Trip"
                />
                <br />
                {!booking.isReturnTripOpen && (
                  <>
                    <CustomInput
                      labelText="Return Route"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: booking.returnCoreApiTrip.route.name
                      }}
                    />
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Return Date"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: moment(
                              booking.returnCoreApiTrip.date
                            ).format("dddd, MMMM Do YYYY")
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText={`Return Time (${returnTimezoneLabel})`}
                          disabled
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: `${booking.returnCoreApiTrip.time.substring(
                              0,
                              2
                            )}:${booking.returnCoreApiTrip.time.substring(
                              2,
                              4
                            )}`
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                )}
              </>
            )}
            {booking.status === 0 && (
              <CustomInput
                labelText="Transaction Time"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: formatDateTime(new Date(booking.transactionTime + "Z"))
                }}
              />
            )}
            {booking.status === 1 && (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Submitted At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(new Date(booking.submittedAt + "Z"))
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Submitted By"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        booking.submittedBy === null ? "" : booking.submittedBy
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

View.propTypes = {
  booking: PropTypes.object,
  history: PropTypes.object
};
