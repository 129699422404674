import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import ReactToPrint from "react-to-print";

// @material-ui/icons
import PrintIcon from "@material-ui/icons/Print";

// core components
import Button from "components/CustomButtons/Button.js";

import { formatCurrency } from "functions/Helper";

import { creditMonitoringApi, agentApi } from "functions/apis";

export default function PrintTopUpInvoice() {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const printComponent = useRef();
  const [topUp, setTopUp] = React.useState(null);
  const [agent, setAgent] = React.useState(null);

  useEffect(() => {
    creditMonitoringApi
      .get("/TopUps/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => setTopUp(data))
      .catch(error => console.log(error));
    agentApi
      .get("/Agents", {
        signal: abortSignal.current.signal
      })
      .then(data => setAgent(data))
      .catch(error => console.log(error));
  }, [id]);

  return (
    topUp !== null &&
    agent !== null && (
      <>
        <ReactToPrint
          trigger={() => (
            <Button color="rose" type="button" value="Create">
              <PrintIcon /> PRINT OUT
            </Button>
          )}
          content={() => printComponent.current}
        />
        <div
          style={{
            display: "none"
          }}
        >
          <div ref={printComponent}>
            <style>
              {`@media print{
                        .page{
                            height: 295mm;
                            max-height: 295mm;
                            width: 210mm;
                            max-width: 210mm;
                        }
                        .invoice{
                            padding: 10mm 10mm 15mm 15mm;
                        }
                        h3{
                            font-weight: bold;
                        }
                        .header{
                            width:100%;
                            height: 35mm;
                            min-height: 35mm;
                            border-bottom: 0.5mm solid;
                        }
                        .header > div{
                            float:left;
                        }
                        .title{
                            width: 100%;
                            text-align: center;
                            margin-top: 10mm;
                            margin-bottom: 10mm;
                        }
                        .title > *{
                            font-weight: bold;
                        }
                        .title > h4{
                            text-decoration: underline;
                            line-height:1mm;
                        }
                        .logo{
                            margin: 7mm 5mm 5mm 5mm;
                        }
                        .logo>img{
                            width: 50mm;
                            max-width: 50mm;
                        }
                        .clear-fix{
                            clear: both;
                        }
                    }`}
            </style>
            <div className="page">
              <div className="invoice">
                <div className="header">
                  <div className="logo">
                    <img alt="logo" src="/sindo-logo-color-sm.png" />
                  </div>
                  <div>
                    <h3>SINDO FERRY PTE LTD</h3>
                    <p>
                      1 Maritime Square #10-32 HarbourFront Centre, Singapore
                      099253
                    </p>
                    <p>Tel: +65-6331 4127 Fax: +65 6271 6496</p>
                  </div>
                </div>
                <div className="clear-fix"></div>
                <div className="agent-details">
                  <div className="title">
                    <h4>PAYMENT RECEIPT/INVOICE</h4>
                    <p>NO: {topUp.paymentNo}</p>
                  </div>
                  <p>
                    <b>Date</b> :{" "}
                    {moment(new Date(topUp.approvedAt + "Z")).format("LL")}
                  </p>
                  <p>
                    <b>Received From</b> : {agent.name}
                  </p>
                  <p>
                    <b>Amount</b> :{" "}
                    {formatCurrency(
                      topUp.currency === null ? "XXX" : topUp.currency.code,
                      topUp.balance
                    )}
                  </p>
                  <p>
                    <b>Fee</b> :{" "}
                    {formatCurrency(
                      topUp.currency === null ? "XXX" : topUp.currency.code,
                      topUp.fee
                    )}
                  </p>
                  <p>
                    <b>Total</b> :{" "}
                    {formatCurrency(
                      topUp.currency === null ? "XXX" : topUp.currency.code,
                      topUp.total
                    )}
                  </p>
                  <p>
                    <b>Purpose</b> : Payment for Top Up No <b>#{topUp.no}</b> in
                    Sindo Ferry&apos;s Agent Portal.
                  </p>
                  <p>
                    <i>
                      *THIS RECEIPT IS GENERATED BY COMPUTER. NO SIGNATURE IS
                      REQUIRED.
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}
