import LoyaltyIcon from "@material-ui/icons/Loyalty";

import VoucherTypeList from "views/VoucherType/VoucherTypeList.js";
import VoucherTypeDetail from "views/VoucherType/VoucherTypeDetail.js";

import { token } from "functions/Helper";

const voucherType = () => {
  let views = [];
  if (
    token["order.agent_voucher_types.read_billing_price"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/VoucherTypes",
      name: "Voucher Types",
      icon: LoyaltyIcon,
      component: VoucherTypeList,
      layout: "/admin"
    });
  if (views.length > 0) return views;
  return [];
};

const voucherTypeHidden = () => {
  let views = [];
  if (
    token["order.voucher_types.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/VoucherTypeDetail",
      name: "Voucher Type",
      component: VoucherTypeDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "VoucherType Hidden",
        state: "voucherTypeHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { voucherType, voucherTypeHidden };
