import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import Create from "views/Booking/Create.js";
import PendingList from "views/Booking/PendingList.js";
import SubmittedList from "views/Booking/SubmittedList";
import Detail from "views/Booking/Detail";
import Delete from "views/Booking/Delete";
import Submit from "views/Booking/Submit";

import { token } from "functions/Helper";
import Edit from "views/Booking/Edit";

const booking = () => {
  let views = [];
  if (token["booking.bookings.read"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/Bookings/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingList,
        layout: "/admin"
      },
      {
        path: "/Bookings/Submitted",
        name: "Paid",
        mini: "SU",
        component: SubmittedList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Bookings",
        icon: MonetizationOnIcon,
        state: "bookingCollapse",
        views: views
      }
    ];
  return [];
};

const bookingHidden = () => {
  let views = [];
  if (token["booking.bookings.read"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/Booking/DetailPassenger",
        name: "Booking Detail - Passenger",
        component: Detail,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["booking.bookings.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/Booking/Create",
        name: "Create Booking",
        component: Create,
        layout: "/admin"
      },
      {
        path: "/Booking/Edit",
        name: "Edit Booking",
        component: Edit,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Booking/Delete",
        name: "Delete Booking",
        component: Delete,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Booking/Submit",
        name: "Submit Booking",
        component: Submit,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Booking Hidden",
        state: "bookingCollapse",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { booking, bookingHidden };
