import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import Datetime from "react-datetime";
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { creditMonitoringApi } from "functions/apis";
import { formatCurrency, formatDateTime } from "functions/Helper";
import { GetBalanceHistorySortOptions } from "enums/filters/BalanceHistorySortOption";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function BalanceHistoryList(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { billingCurrency } = props;
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [filter, setFilter] = React.useState({
    minMutationTime: null,
    maxMutationTime: null,
    sort: 1
  });
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [loading, setLoading] = React.useState(false);

  const handleSortChanged = e => {
    if (filter.sort !== e.target.value) {
      setFilter({
        ...filter,
        sort: e.target.value
      });
      setExportCSV(defaultExportCSV);
    }
  };
  const handleFromDateChanged = e => {
    if (filter.minMutationTime !== e) {
      setFilter({
        ...filter,
        minMutationTime: e
      });
      setExportCSV(defaultExportCSV);
    }
  };

  const handleToDateChanged = e => {
    if (filter.maxMutationTime !== e) {
      setFilter({
        ...filter,
        maxMutationTime: e
      });
      setExportCSV(defaultExportCSV);
    }
  };
  const defaultExportCSV = {
    data: null,
    filename: null
  };
  const [exportCSV, setExportCSV] = React.useState(defaultExportCSV);
  const handleExportToCSV = () => {
    creditMonitoringApi
      .get("/Agents/BalanceHistories", {
        signal: abortSignal.current.signal,
        params: {
          filter: filter,
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => {
        let startString =
          filter.minMutationTime === null
            ? "start"
            : moment(filter.minMutationTime)
                .local()
                .format("YYYYMMDD");
        let untilString =
          filter.maxMutationTime === null
            ? "now"
            : moment(filter.maxMutationTime)
                .local()
                .format("YYYYMMDD");
        setExportCSV({
          data: data.records.map(record => {
            return {
              transactionTime: record.mutationTime,
              transactionAmount: record.mutationBalance,
              currentBalance: record.currentBalance,
              description: record.description
            };
          }),
          filename: "balanceHistory_" + startString + "_" + untilString + ".csv"
        });
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    setLoading(true);
    creditMonitoringApi
      .get("/Agents/BalanceHistories", {
        signal: abortSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(data.records);
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>BALANCE HISTORIES</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <InputLabel className={classes.label}>
                  Transaction Time:{" "}
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    dateFormat={"YYYY-MMM-DD"}
                    value={filter.minMutationTime}
                    onChange={handleFromDateChanged}
                    inputProps={{
                      placeholder: "From"
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    dateFormat={"YYYY-MMM-DD"}
                    value={filter.maxMutationTime}
                    onChange={handleToDateChanged}
                    inputProps={{
                      placeholder: "To"
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="selSortOption"
                className={classes.selectLabel}
              >
                Sort Option
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={filter.sort}
                onChange={handleSortChanged}
                inputProps={{
                  name: "selSortOption"
                }}
              >
                {GetBalanceHistorySortOptions().map(record => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={record.key}
                      key={record.key}
                    >
                      <p>{record.description}</p>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem>
            <Button onClick={handleExportToCSV} color="rose">
              Export to CSV
            </Button>
            {exportCSV.data === null ? null : (
              <CSVLink data={exportCSV.data} filename={exportCSV.filename}>
                {exportCSV.filename}
              </CSVLink>
            )}
          </GridItem>
        </GridContainer>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={data}
          filterable={false}
          columns={[
            {
              id: "mutationTime",
              Header: "Transaction Time",
              accessor: d => formatDateTime(new Date(d.mutationTime + "Z"))
            },
            {
              id: "mutationBalance",
              Header: "Transaction Amount",
              accessor: d =>
                formatCurrency(billingCurrency.code, d.mutationBalance)
            },
            {
              id: "currentBalance",
              Header: "Current Balance",
              accessor: d =>
                formatCurrency(billingCurrency.code, d.currentBalance)
            },
            {
              Header: "Description",
              headerStyle: { textAlign: "left" },
              accessor: "description"
            }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

BalanceHistoryList.propTypes = {
  billingCurrency: PropTypes.object
};
