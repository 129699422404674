import LoginPage from "views/Pages/LoginPage.js";

//
import { dashboard } from "routes/dashboard";
import { profile, profileHidden } from "routes/profile";
import { security, userHidden, roleHidden } from "routes/security";
import {
  notificationList,
  notificationListHidden,
  notificationConfigHidden
} from "routes/notification";
import { commission, commissionHidden } from "routes/commission";
import { topUp, topUpHidden } from "routes/top_up";
import { order, orderHidden } from "routes/order";
import { booking, bookingHidden } from "routes/booking";
import {
  orderCancellation,
  orderCancellationHidden
} from "routes/order_cancellation";
import {
  bookingCancellation,
  bookingCancellationHidden
} from "routes/booking_cancellation";
import {
  bookingTransfer,
  bookingTransferHidden
} from "routes/booking_transfer";
import { voucherType, voucherTypeHidden } from "routes/voucher_type";
import { bookingType, bookingTypeHidden } from "routes/booking_type";
import { report } from "routes/report";
import { promos } from "routes/promos";
import { help } from "routes/help";

import ChangePassword from "views/Profile/ChangePassword";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import SettingsIcon from "@material-ui/icons/Settings";

var priceList = [];
var voucherTypePriceList = voucherType();
var bookingTypePriceList = bookingType();
if (voucherTypePriceList.length + bookingTypePriceList.length > 0)
  priceList.push({
    collapse: true,
    name: "Pricings",
    icon: MonetizationOn,
    state: "pricingCollapse",
    views: voucherTypePriceList.concat(bookingTypePriceList)
  });

var profileMenu = profile();
var securitySetting = security();
var notificationSetting = notificationList();
if (securitySetting.length + notificationSetting.length > 0) {
  profileMenu[0].views.push({
    collapse: true,
    name: "Settings",
    icon: SettingsIcon,
    state: "settingCollapse",
    views: securitySetting.concat(notificationSetting)
  });
}

var dashRoutes = []
  .concat(dashboard())
  .concat(profileMenu)
  .concat(profileHidden())
  .concat(userHidden())
  .concat(roleHidden())
  .concat(notificationListHidden())
  .concat(notificationConfigHidden())
  .concat(commission())
  .concat(commissionHidden())
  .concat(priceList)
  .concat(voucherTypeHidden())
  .concat(bookingTypeHidden())
  .concat(topUp())
  .concat(topUpHidden())
  .concat(order())
  .concat(orderHidden())
  .concat(booking())
  .concat(bookingHidden())
  .concat(orderCancellation())
  .concat(orderCancellationHidden())
  .concat(bookingCancellation())
  .concat(bookingCancellationHidden())
  .concat(bookingTransfer())
  .concat(bookingTransferHidden())
  .concat(promos())
  .concat(report())
  .concat(help())
  .concat([
    {
      collapse: true,
      name: "Hide",
      icon: Image,
      state: "hideCollapse",
      hideInSidebar: true,
      views: [
        {
          path: "/login",
          name: "Login",
          component: LoginPage,
          layout: "/auth"
        },
        {
          path: "/Profile/ChangePassword",
          name: "Change Password",
          component: ChangePassword,
          layout: "/admin"
        }
      ]
    }
  ]);
export default dashRoutes;
