import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import { GetTopUpStatusDescription } from "enums/TopUpStatus.js";
import { GetTopUpMethodDescription } from "enums/TopUpMethod";

import { creditMonitoringApi } from "functions/apis";
import { formatDateTime, token } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function TopUpDetail(props) {
  const abortSignal = useRef(new AbortController());
  const [agentCredit, setAgentCredit] = React.useState(null);
  useEffect(() => {
    if (
      token["creditmonitoring.agents.read"] === "allowed" ||
      token.is_admin === "True"
    )
      creditMonitoringApi
        .get("/Agents", {
          signal: abortSignal.current.signal
        })
        .then(data => setAgentCredit(data))
        .catch(error => console.log(error));
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [topUp, setTopUp] = React.useState(null);

  useEffect(() => {
    creditMonitoringApi
      .get("/TopUps/" + id, {
        signal: abortSignal.current.signal
      })
      .then(res => setTopUp(res))
      .catch(error => console.log(error));
  }, [id]);

  const classes = useStyles();
  return (
    topUp !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>TOP UP</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              {topUp.method !== 0 && topUp.status === 0 && (
                <SnackbarContent
                  message={
                    <>
                      <p>
                        Your Top Up method is non-manual approval. System will
                        approve the Top Up after you have completed the Payment.
                      </p>
                      <p>System might have delay to complete the approval.</p>
                      <p>
                        <a
                          href={`${process.env.REACT_APP_PAYMENT_URL}/payment/${topUp.paymentID}?callbackUrl=${process.env.REACT_APP_SITE_URL}/admin/TopUps/Detail/${id}`}
                        >
                          Click Here
                        </a>{" "}
                        to continue your Payment in case you did not complete
                        it.
                      </p>
                    </>
                  }
                  color="success"
                />
              )}
              <CustomInput
                labelText="No"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: topUp.no
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={3} md={3}>
                  <CustomInput
                    labelText="Billing Currency"
                    disabled
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agentCredit === null ||
                        agentCredit.billingCurrency === null
                          ? ""
                          : agentCredit.billingCurrency.code
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <CustomInput
                    labelText="Balance"
                    id="numBalance"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: topUp.balance.toLocaleString()
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <CustomInput
                    labelText="Fee"
                    id="numFee"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: topUp.fee.toLocaleString()
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <CustomInput
                    labelText="Total"
                    id="numTotal"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: topUp.total.toLocaleString()
                    }}
                  />
                </GridItem>
              </GridContainer>
              <CustomInput
                labelText="Note"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: topUp.note === null ? "" : topUp.note
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Status"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: GetTopUpStatusDescription(topUp.status)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Method"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: GetTopUpMethodDescription(topUp.method)
                    }}
                  />
                </GridItem>
              </GridContainer>
              {topUp.status === 0 && (
                <CustomInput
                  labelText="Created At"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: formatDateTime(new Date(topUp.createdAt + "Z"))
                  }}
                />
              )}
              {topUp.status === 1 && (
                <>
                  <CustomInput
                    labelText="Approved At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(new Date(topUp.approvedAt + "Z"))
                    }}
                  />
                  <CustomInput
                    labelText="Payment No"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: topUp.paymentNo
                    }}
                  />
                </>
              )}
              {topUp.status === 2 && (
                <>
                  <CustomInput
                    labelText="Rejected At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(new Date(topUp.rejectedAt + "Z"))
                    }}
                  />
                  <CustomInput
                    labelText="Reject Note"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: topUp.rejectNote === null ? "" : topUp.rejectNote
                    }}
                  />
                </>
              )}
              {topUp.hasPaymentReceiptFile ? (
                <Button
                  color="info"
                  className="like"
                  onClick={() =>
                    axios
                      .get(
                        process.env.REACT_APP_CREDIT_MONITORING_API_URL +
                          "/TopUps/PaymentReceipt/" +
                          topUp.id,
                        {
                          responseType: "arraybuffer",
                          headers: {
                            Authorization: localStorage.getItem("Authorization")
                          }
                        }
                      )
                      .then(response => {
                        let filename = response.request
                          .getResponseHeader("Content-Disposition")
                          .split(";")[2]
                          .replace("filename*=UTF-8''", "");
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                      })
                  }
                >
                  Download
                </Button>
              ) : (
                <div>No File Attached</div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

TopUpDetail.propTypes = {
  history: PropTypes.object
};
