import React, { useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { agentApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function CreateNotificationConfig(props) {
  const abortSignal = useRef(new AbortController());

  const [notificationList, setNotificationList] = React.useState([]);
  const [availableEvents, setAvailableEvents] = React.useState([]);
  useEffect(() => {
    agentApi
      .get("/NotificationLists", {
        signal: abortSignal.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null
          }
        }
      })
      .then(data => setNotificationList(data.records))
      .catch(error => console.log(error));

    agentApi
      .get("/NotificationConfigs/AvailableEvents", {
        signal: abortSignal.current.signal
      })
      .then(data => setAvailableEvents(data))
      .catch(error => console.log(error));

    return () => abortSignal.current.abort();
  }, []);

  const [state, setState] = React.useState({
    eventName: null,
    notificationListID: null
  });

  const handleEventNameChanged = e =>
    setState({
      ...state,
      eventName: e.target.value === "" ? null : e.target.value
    });

  const handleNotificationListIDChanged = e =>
    setState({
      ...state,
      notificationListID: e.target.value
    });

  const classes = useStyles();

  const [redirect, setRedirect] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    agentApi
      .post("/NotificationConfigs", state, {
        signal: abortSignal.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => console.log(error));
  };
  return redirect ? (
    <Redirect to={"/admin/NotificationConfigList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              CREATE NOTIFICATION CONFIG
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selEventName"
                  className={classes.selectLabel}
                >
                  Event Name
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={state.eventName === null ? "" : state.eventName}
                  onChange={handleEventNameChanged}
                >
                  {availableEvents.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record}
                        key={record}
                      >
                        <b>{record}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selNotificationList"
                  className={classes.selectLabel}
                >
                  Notification List
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={
                    state.notificationListID === null
                      ? ""
                      : state.notificationListID
                  }
                  onChange={handleNotificationListIDChanged}
                >
                  {notificationList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        <b>{record.name}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Button color="rose" type="submit" value="Submit">
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateNotificationConfig.propTypes = {
  history: PropTypes.object
};
