import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { GetBookingTransferStatusDescription } from "enums/BookingTransferStatus";

import { bookingApi, creditMonitoringApi } from "functions/apis";
import { token } from "functions/Helper";
import View from "./View";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import DetailList from "./DetailList";

const useStyles = makeStyles(styles);

export default function Detail(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [agentCredit, setAgentCredit] = React.useState(null);
  const [bookingTransfer, setBookingTransfer] = React.useState(null);
  const [reload, setReload] = React.useState({});

  useEffect(() => {
    if (
      token["creditmonitoring.agents.read"] === "allowed" ||
      token.is_admin === "True"
    )
      creditMonitoringApi
        .get("/Agents", {
          signal: abortSignal.current.signal
        })
        .then(data => setAgentCredit(data))
        .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    bookingApi
      .get("/BookingTransfers/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => setBookingTransfer(data))
      .catch(error => console.log(error));
  }, [id, reload]);

  const classes = useStyles();
  return (
    bookingTransfer !== null &&
    agentCredit !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>ACTIONS</h4>
            </CardHeader>
            <CardBody>
              <p>
                <i>
                  *Booking Transfer status is &apos;
                  <b>
                    {GetBookingTransferStatusDescription(
                      bookingTransfer.status
                    )}
                  </b>
                  &apos;.
                </i>
              </p>
              {bookingTransfer.status === 0 &&
                (token["booking.booking_transfers.write"] === "allowed" ||
                  token.is_admin === "True") && (
                  <>
                    <Link to={"/admin/BookingTransfer/Delete/" + id}>
                      <Button color="rose" value="Delete">
                        DELETE
                      </Button>
                    </Link>
                    <Link to={"/admin/BookingTransfer/Submit/" + id}>
                      <Button color="info" className="like">
                        SUBMIT
                      </Button>
                    </Link>
                  </>
                )}
            </CardBody>
          </Card>
          <View
            bookingTransfer={bookingTransfer}
            history={props.history}
            currency={
              agentCredit.billingCurrency === null
                ? ""
                : agentCredit.billingCurrency.code
            }
          />
          {bookingTransfer.status === 0 ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <DetailList
                  bookingTransfer={bookingTransfer}
                  reload={reload}
                  setReload={setReload}
                />
              </GridItem>
            </GridContainer>
          ) : (
            <DetailList bookingTransfer={bookingTransfer} />
          )}
        </GridItem>
      </GridContainer>
    )
  );
}

Detail.propTypes = {
  history: PropTypes.object
};
