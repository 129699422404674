import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { bookingApi } from "functions/apis";
import GridContainer from "components/Grid/GridContainer";

import styles from "assets/jss/material-dashboard-pro-react/views/Booking/passengerList";

const useStyles = makeStyles(styles);

export default function CancellationDetailList(props) {
  const { bookingCancellation } = props;
  const abortSignal = useRef(new AbortController());

  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null
  });
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    bookingApi
      .get(`/BookingCancellations/${bookingCancellation.id}/Details`, {
        signal: abortSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(data.records);
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>FOR CANCELLATION</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Search"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleSearchStringChanged,
                value: filter.searchString === null ? "" : filter.searchString
              }}
            />
          </GridItem>
        </GridContainer>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={data}
          filterable={false}
          columns={[
            {
              id: "no",
              Header: "No",
              accessor: d => d.bookingDetail.identification.no
            },
            {
              id: "fullName",
              Header: "Full Name",
              accessor: d => d.bookingDetail.identification.fullName
            },
            {
              id: "departureVoucher",
              Header: "Departure Voucher",
              accessor: d =>
                d.bookingDetail.departureVoucherCode === null
                  ? "NO UTILISED VOUCHER"
                  : d.bookingDetail.departureVoucherCode.id
            },
            {
              id: "returnVoucher",
              Header: "Return Voucher",
              accessor: d =>
                d.bookingDetail.returnVoucherCode === null
                  ? "NO UTILISED VOUCHER"
                  : d.bookingDetail.returnVoucherCode.id
            }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

CancellationDetailList.propTypes = {
  bookingCancellation: PropTypes.object,
  reload: PropTypes.object,
  setReload: PropTypes.func
};
