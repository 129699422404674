import BusinessIcon from "@material-ui/icons/Business";

import MyAgent from "views/Profile/MyAgent.js";
import MyUser from "views/Profile/MyUser.js";
import EditCreditInformation from "views/Profile/EditCreditInformation";
import Notification from "views/Profile/Notification";

import { token } from "functions/Helper";

const profile = () => {
  let views = [
    {
      path: "/MyUser",
      name: "My User",
      mini: "ME",
      component: MyUser,
      layout: "/admin"
    }
  ];
  if (token["agent.agents.read"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/MyAgent",
      name: "My Agent",
      mini: "MA",
      component: MyAgent,
      layout: "/admin"
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Profile",
        icon: BusinessIcon,
        state: "profileCollapse",
        views: views
      }
    ];
  return [];
};

const profileHidden = () => {
  let views = [
    {
      path: "/MyNotification",
      name: "My Notification",
      component: Notification,
      layout: "/admin"
    }
  ];
  if (
    token["credimonitoring.agents.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/EditCreditInformation",
      name: "Edit Credit Information",
      component: EditCreditInformation,
      layout: "/admin"
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Profile Hidden",
        state: "profileHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};
export { profile, profileHidden };
