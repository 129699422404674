import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { bookingApi } from "functions/apis";
import { GetGenderDescription } from "enums/Gender";
import GridContainer from "components/Grid/GridContainer";
import moment from "moment";

import styles from "assets/jss/material-dashboard-pro-react/views/Booking/passengerList";

const useStyles = makeStyles(styles);

export default function ViewPassengerList(props) {
  const { id } = props;
  const abortSignal = useRef(new AbortController());
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    bookingApi
      .get(`/Bookings/${id}/Details`, {
        signal: abortSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(data.records);
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>PASSENGERS LIST</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Search"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleSearchStringChanged,
                value: filter.searchString === null ? "" : filter.searchString
              }}
            />
          </GridItem>
        </GridContainer>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={data}
          filterable={false}
          columns={[
            {
              id: "no",
              Header: "No",
              accessor: d => d.identification.no
            },
            {
              id: "fullName",
              Header: "Full Name",
              accessor: d => d.identification.fullName
            },
            {
              id: "gender",
              Header: "Gender",
              accessor: d => GetGenderDescription(d.identification.gender)
            },
            {
              id: "dateOfBirth",
              Header: "Date of Birth",
              accessor: d =>
                moment(d.identification.dateOfBirth).format(dateFormat)
            },
            {
              id: "nationality",
              Header: "Nationality",
              accessor: d => d.identification.nationality.nationality
            },
            {
              id: "issuanceCountry",
              Header: "Issuance Country",
              accessor: d => d.identification.issuanceCountry.name
            },
            {
              id: "issueDate",
              Header: "Issue Date",
              accessor: d =>
                moment(d.identification.issueDate).format(dateFormat)
            },
            {
              id: "expiryDate",
              Header: "Expiry Date",
              accessor: d =>
                moment(d.identification.expiryDate).format(dateFormat)
            }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

ViewPassengerList.propTypes = {
  id: PropTypes.string
};
