import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);
export default function ProductPowerSaleChallenge() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Product Power Sale Challenge
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={10} sm={10} md={10}>
                <Fade>
                  <div className="each-slide">
                    <img
                      width="100%"
                      src={"/dashboard/202201162014_product_power_sale.jpg"}
                      alt="Banner"
                    />
                  </div>
                  <div className="each-slide">
                    <img
                      width="100%"
                      src={
                        "/product-power-sale-challenge/product_power_sale_challenge_terms_and_conditions.jpg"
                      }
                      alt="Terms & Conditions"
                    />
                  </div>
                </Fade>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
