import React from "react";
import PropTypes from "prop-types";

import CustomInput from "components/CustomInput/CustomInput.js";

export default function ViewRole(props) {
  const { role } = props;
  return (
    <CustomInput
      labelText="Name"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        disabled: true,
        value: role.name === null ? "" : role.name
      }}
    />
  );
}

ViewRole.propTypes = {
  role: PropTypes.object
};
