export function GetVoucherTypeValidityTypes() {
  return [
    {
      key: 0,
      description: "MONTH"
    },
    {
      key: 1,
      description: "WEEK"
    },
    {
      key: 2,
      description: "DAY"
    }
  ];
}

export function GetVoucherTypeValidityTypeDescription(value) {
  let voucherTypeValidityType = GetVoucherTypeValidityTypes().find(
    item => item.key === value
  );
  return voucherTypeValidityType.description;
}
