import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function Index() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Report</h4>
          </CardHeader>
          <CardBody>
            <Link
              to="reporting"
              target="_blank"
              onClick={event => {
                event.preventDefault();
                window.open(
                  process.env.REACT_APP_REPORTING_URL +
                    "?pp=" +
                    encodeURI(
                      "agent|" +
                        localStorage
                          .getItem("Authorization")
                          .replace("Bearer ", "")
                    ) +
                    "&userTimezone=" +
                    encodeURI(Intl.DateTimeFormat().resolvedOptions().timeZone)
                );
              }}
            >
              <Button color="rose" type="button" value="View">
                View Reports
              </Button>
            </Link>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
