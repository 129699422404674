import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function ViewUser(props) {
  const { user } = props;
  const classes = useStyles();
  return (
    <>
      <CustomInput
        labelText="UserName"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: user.userName === null ? "" : user.userName
        }}
      />
      <CustomInput
        labelText="Email"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: user.email === null ? "" : user.email
        }}
      />
      <GridContainer>
        <GridItem>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                checked={user.isSuperAdmin}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label="Is Super Admin"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                checked={user.isAdmin}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label="Is Admin"
          />
        </GridItem>
      </GridContainer>
    </>
  );
}

ViewUser.propTypes = {
  user: PropTypes.object
};
