import React, { useEffect, useRef } from "react";
import Moment from "moment";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/BookingTransfer/create";
import ViewPassengerList from "./ViewPassengerList";

import { bookingApi, masterApi, coreApi } from "functions/apis";
import { getTimezoneLabel, token } from "functions/Helper";
import moment from "moment";
import BookingDialog from "components/BookingDialog/BookingDialog";

const useStyles = makeStyles(styles);

export default function Create(props) {
  const abortSignal = useRef(new AbortController());

  const [
    availableDepartureSectorList,
    setAvailableDepartureSectorList
  ] = React.useState([]);
  const [departureSectorID, setDepartureSectorID] = React.useState(null);
  const [returnSectorID, setReturnSectorID] = React.useState(null);
  const [departureRouteList, setDepartureRouteList] = React.useState([]);
  const [returnRouteList, setReturnRouteList] = React.useState([]);
  const [departureScheduleList, setDepartureScheduleList] = React.useState([]);
  const [returnScheduleList, setReturnScheduleList] = React.useState([]);
  const [selectedBookingNo, setSelectedBookingNo] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    bookingApi
      .get("/Sectors/Available", {
        signal: abortSignal.current.signal
      })
      .then(data => setAvailableDepartureSectorList(data.records))
      .catch(error => console.log(error));

    return () => abortSignal.current.abort();
  }, []);

  useEffect(() => {
    if (departureSectorID !== null) {
      masterApi
        .get("/Routes", {
          signal: abortSignal.current.signal,
          params: {
            filter: {
              searchString: null,
              sectorID: departureSectorID,
              sort: 2
            },
            pagination: {
              pageIndex: 0,
              pageSize: 0
            }
          }
        })
        .then(data => setDepartureRouteList(data.records))
        .catch(error => console.log(error));

      let selectedDepartureSector = availableDepartureSectorList.find(
        s => s.id === departureSectorID
      );
      setReturnSectorID(selectedDepartureSector.nextSector?.id);
    } else {
      setDepartureRouteList([]);
      setReturnSectorID(null);
    }
  }, [departureSectorID]);

  useEffect(() => {
    if (returnSectorID != null) {
      masterApi
        .get("/Routes", {
          signal: abortSignal.current.signal,
          params: {
            filter: {
              searchString: null,
              sectorID: returnSectorID
            },
            pagination: {
              pageIndex: 0,
              pageSize: 0
            }
          }
        })
        .then(data => setReturnRouteList(data.records))
        .catch(error => console.log(error));
    } else {
      setReturnRouteList([]);
    }
  }, [returnSectorID]);

  const handleDepartureSectorIDChanged = e => {
    setDepartureSectorID(e.target.value);
    setState({
      ...state,
      departureCoreApiTrip: {
        ...defaultCoreApiTrip
      },
      isReturnTripOpen: selectedBooking.isRoundTrip,
      returnCoreApiTrip: selectedBooking.isRoundTrip
        ? {
            ...defaultCoreApiTrip
          }
        : null
    });
  };

  let defaultCoreApiTrip = {
    date: null,
    routeID: null,
    id: null,
    time: null,
    gateOpen: null,
    gateClose: null
  };

  const [state, setState] = React.useState({
    bookingID: null,
    transferDepartureTrip: false,
    departureCoreApiTrip: null,
    transferReturnTrip: false,
    isReturnTripOpen: false,
    returnCoreApiTrip: null
  });

  const [selectedBooking, setSelectedBooking] = React.useState(null);

  const handleBookingIDChanged = e => {
    setSelectedBookingNo(e.no);
    setState({
      ...state,
      bookingID: e === null ? null : e.id
    });

    setSelectedBooking(e);
    setOpenModal(false);
    setDepartureSectorID(e.departureCoreApiTrip.route.sector.id);
    if (e.isRoundTrip)
      setReturnSectorID(e.departureCoreApiTrip.route.sector.nextSector.id);
  };

  const handleTransferDepartureTripChanged = e => {
    let val = e.target.checked;
    setState({
      ...state,
      transferDepartureTrip: val,
      departureCoreApiTrip: val
        ? {
            ...defaultCoreApiTrip
          }
        : null
    });
  };

  const handleDepartureDateChanged = e => {
    let newDate = e === "" ? null : e;
    setState({
      ...state,
      departureCoreApiTrip: {
        ...state.departureCoreApiTrip,
        date: e === "" ? null : e,
        id: null,
        time: null,
        gateOpen: null,
        gateClose: null
      }
    });
    updateScheduleList(
      state.departureCoreApiTrip.routeID,
      newDate,
      departureRouteList,
      setDepartureScheduleList
    );
  };

  const handleDepartureRouteIDChanged = e => {
    let newRouteID = e.target.value === "" ? null : e.target.value;
    setState({
      ...state,
      departureCoreApiTrip: {
        ...state.departureCoreApiTrip,
        routeID: newRouteID,
        id: null,
        time: null,
        gateOpen: null,
        gateClose: null
      }
    });
    updateScheduleList(
      newRouteID,
      state.departureCoreApiTrip.date,
      departureRouteList,
      setDepartureScheduleList
    );
  };

  const handleDepartureTripChanged = e => {
    let selectedTripTime = e.target.value === "" ? null : e.target.value;
    if (selectedTripTime !== null) {
      let selectedTrip = departureScheduleList.find(
        schedule => schedule.tripTime === selectedTripTime
      );
      setState({
        ...state,
        departureCoreApiTrip: {
          ...state.departureCoreApiTrip,
          id: selectedTrip.tripID,
          gateOpen: selectedTrip.gateOpen,
          time: selectedTrip.tripTime,
          gateClose: selectedTrip.gateClose
        }
      });
    } else {
      setState({
        ...state,
        departureCoreApiTrip: {
          ...state.departureCoreApiTrip,
          id: null,
          gateOpen: null,
          time: null,
          gateClose: null
        }
      });
    }
  };

  const handleTransferReturnTripChanged = e => {
    let val = e.target.checked;
    setState({
      ...state,
      transferReturnTrip: val,
      isReturnTripOpen: false,
      returnCoreApiTrip: val
        ? {
            ...defaultCoreApiTrip
          }
        : null
    });
  };

  const handleReturnDateChanged = e => {
    let newDate = e === "" ? null : e;
    setState({
      ...state,
      returnCoreApiTrip: {
        ...state.returnCoreApiTrip,
        date: e === "" ? null : e,
        id: null,
        time: null,
        gateOpen: null,
        gateClose: null
      }
    });
    updateScheduleList(
      state.returnCoreApiTrip.routeID,
      newDate,
      returnRouteList,
      setReturnScheduleList
    );
  };

  const handleReturnRouteIDChanged = e => {
    let newRouteID = e.target.value === "" ? null : e.target.value;
    setState({
      ...state,
      returnCoreApiTrip: {
        ...state.returnCoreApiTrip,
        routeID: newRouteID,
        id: null,
        time: null,
        gateOpen: null,
        gateClose: null
      }
    });
    updateScheduleList(
      newRouteID,
      state.returnCoreApiTrip.date,
      returnRouteList,
      setReturnScheduleList
    );
  };

  const handleReturnTripChanged = e => {
    let selectedTripTime = e.target.value === "" ? null : e.target.value;
    if (selectedTripTime !== null) {
      let selectedTrip = returnScheduleList.find(
        schedule => schedule.tripTime === selectedTripTime
      );
      setState({
        ...state,
        returnCoreApiTrip: {
          ...state.returnCoreApiTrip,
          id: selectedTrip.tripID,
          gateOpen: selectedTrip.gateOpen,
          time: selectedTrip.tripTime,
          gateClose: selectedTrip.gateClose
        }
      });
    } else {
      setState({
        ...state,
        returnCoreApiTrip: {
          ...state.returnCoreApiTrip,
          id: null,
          gateOpen: null,
          time: null,
          gateClose: null
        }
      });
    }
  };

  const handleIsReturnTripOpenChanged = e => {
    let val = e.target.checked;
    setState({
      ...state,
      isReturnTripOpen: val,
      returnCoreApiTrip: val
        ? null
        : {
            ...defaultCoreApiTrip
          }
    });
  };

  const updateScheduleList = (routeID, date, routeList, setScheduleList) => {
    if (routeID !== null && date !== null) {
      let selectedRoute = routeList.find(r => r.id === routeID);
      let ports = selectedRoute.code.split(" - ");
      let embarkationPort = ports[0];
      let destinationPort = ports[1];
      if (embarkationPort === "TMFT") embarkationPort = "TMF";
      if (destinationPort === "TMFT") destinationPort = "TMF";
      bookingApi
        .get(`/Ports/ScheduleTimezone/${selectedRoute.embarkationPort.id}`)
        .then(data => {
          let scheduleTimezone = data;
          coreApi
            .get("/Trips/GetTripWeb", {
              signal: abortSignal.current.signal,
              params: {
                embarkation: embarkationPort,
                destination: destinationPort,
                tripDate: Moment(date).format("yyyyMMDD"),
                paxCount: 1,
                agentID: token.aid
              }
            })
            .then(data =>
              setScheduleList(
                data.data.map(record => {
                  let status =
                    record.usedSeat > 0 ? (
                      <p className={classes.green}>AVAILABLE</p>
                    ) : (
                      <p className={classes.red}>NO SEAT LEFT</p>
                    );
                  if (record.usedSeat <= 10)
                    status = (
                      <p
                        className={classes.red}
                      >{`${record.usedSeat} seat(s) left!`}</p>
                    );
                  else if (record.usedSeat <= 50)
                    status = <p className={classes.orange}>SELLING FAST</p>;
                  return {
                    ...record,
                    tripTime: record.departureTime.replace(":", ""),
                    status: status,
                    timezoneLabel: getTimezoneLabel(scheduleTimezone)
                  };
                })
              )
            )
            .catch(error => {
              console.log(error);
              setScheduleList([]);
            });
        })
        .catch(error => {
          console.log(error);
          setScheduleList([]);
        });
    } else {
      setScheduleList([]);
    }
  };
  const [disabled, setDisabled] = React.useState(false);
  const [redirection, setRedirection] = React.useState({
    redirect: false,
    id: null
  });
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post("/BookingTransfers", state, {
        signal: abortSignal.current.signal
      })
      .then(data =>
        setRedirection({
          redirect: true,
          id: data
        })
      )
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  const classes = useStyles();
  return redirection.redirect ? (
    <Redirect to={`/admin/BookingTransfer/Detail/${redirection.id}`} />
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>CREATE BOOKING TRANSFER</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={handleSubmit}>
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    className={classes.option}
                    value={selectedBookingNo}
                    label={<b>BOOKING</b>}
                    variant="outlined"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    fullWidth={true}
                  />
                </GridItem>
                <BookingDialog
                  onSelect={handleBookingIDChanged}
                  openModal={openModal}
                  setOpenModal={val => {
                    setOpenModal(val);
                  }}
                  url={"/BookingTransfers/AvailableForTransfer"}
                />
                {selectedBooking !== null && (
                  <>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Confirmation Code"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: selectedBooking.confirmationCode
                        }}
                      />
                    </GridItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.transferDepartureTrip}
                          onChange={handleTransferDepartureTripChanged}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.selectLabel,
                        root: classes.labelRoot
                      }}
                      label={<b>Transfer Departure Trip</b>}
                    />
                    <br />
                    {state.transferDepartureTrip && (
                      <>
                        <GridContainer>
                          <GridItem xs={12} sm={4} md={4}>
                            <CustomInput
                              labelText="Latest Departure Route"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value:
                                  selectedBooking.latestDepartureCoreApiTrip
                                    .route.name
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                            <CustomInput
                              labelText="Latest Departure Date"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value: moment(
                                  selectedBooking.latestDepartureCoreApiTrip
                                    .date
                                ).format("dddd, MMMM Do YYYY")
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                            <CustomInput
                              labelText="Latest Departure Time"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value: `${selectedBooking.latestDepartureCoreApiTrip.time.substring(
                                  0,
                                  2
                                )}:${selectedBooking.latestDepartureCoreApiTrip.time.substring(
                                  2,
                                  4
                                )}`
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="selDepartureSector"
                            className={classes.selectLabel}
                          >
                            <b>Departure Sector</b>
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={
                              departureSectorID === null
                                ? ""
                                : departureSectorID
                            }
                            onChange={handleDepartureSectorIDChanged}
                            inputProps={{
                              name: "selDepartureSector",
                              id: "selDepartureSector"
                            }}
                          >
                            {availableDepartureSectorList.map(record => {
                              return (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={record.id}
                                  key={record.id}
                                >
                                  {record.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <GridContainer>
                          <GridItem xs={12}>
                            <InputLabel className={classes.selectLabel}>
                              <b>Departure Date</b>
                            </InputLabel>
                            <FormControl fullWidth>
                              <Datetime
                                value={state.departureCoreApiTrip.date}
                                onChange={handleDepartureDateChanged}
                                dateFormat={"dddd, MMMM Do YYYY"}
                                timeFormat={false}
                                utc={true}
                                closeOnSelect={true}
                                inputProps={{
                                  placeholder: "Departure Date"
                                }}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="selDepartureRoute"
                                className={classes.selectLabel}
                              >
                                <b>Departure Route</b>
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={
                                  state.departureCoreApiTrip.routeID === null
                                    ? ""
                                    : state.departureCoreApiTrip.routeID
                                }
                                onChange={handleDepartureRouteIDChanged}
                                inputProps={{
                                  name: "selDepartureRoute",
                                  id: "selDepartureRoute"
                                }}
                              >
                                {departureRouteList.map(record => {
                                  return (
                                    <MenuItem
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }}
                                      value={record.id}
                                      key={record.id}
                                    >
                                      {record.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="selDepartureTrip"
                                className={classes.selectLabel}
                              >
                                <b>Departure Time</b>
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={
                                  state.departureCoreApiTrip.time === null
                                    ? ""
                                    : state.departureCoreApiTrip.time
                                }
                                onChange={handleDepartureTripChanged}
                                inputProps={{
                                  name: "selDepartureTrip",
                                  id: "selDepartureTrip"
                                }}
                              >
                                {departureScheduleList.map(record => {
                                  return (
                                    <MenuItem
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }}
                                      value={record.tripTime}
                                      key={record.tripTime}
                                    >
                                      <p>
                                        <b>{`${record.tripTime.substring(
                                          0,
                                          2
                                        )}:${record.tripTime.substring(
                                          2,
                                          4
                                        )}`}</b>{" "}
                                        {record.timezoneLabel}
                                      </p>
                                      <b>{record.status}</b>
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                      </>
                    )}
                    {selectedBooking.isRoundTrip && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.transferReturnTrip}
                              onChange={handleTransferReturnTripChanged}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.selectLabel,
                            root: classes.labelRoot
                          }}
                          label={<b>Transfer Return Trip</b>}
                        />
                        <br />
                        {state.transferReturnTrip && (
                          <>
                            {selectedBooking.latestIsReturnTripOpen ? (
                              <>
                                <FormControlLabel
                                  disabled
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedBooking.latestIsReturnTripOpen
                                      }
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                      }}
                                    />
                                  }
                                  classes={{
                                    label: classes.label,
                                    root: classes.labelRoot
                                  }}
                                  label="Latest Open Return Trip"
                                />
                                <br />
                              </>
                            ) : (
                              <GridContainer>
                                <GridItem xs={12} sm={4} md={4}>
                                  <CustomInput
                                    labelText="Latest Return Route"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      disabled: true,
                                      value:
                                        selectedBooking.latestReturnCoreApiTrip
                                          .route.name
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                  <CustomInput
                                    labelText="Latest Return Date"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      disabled: true,
                                      value: moment(
                                        selectedBooking.latestReturnCoreApiTrip
                                          .date
                                      ).format("dddd, MMMM Do YYYY")
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                  <CustomInput
                                    labelText="Latest Return Time"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      disabled: true,
                                      value: `${selectedBooking.latestReturnCoreApiTrip.time.substring(
                                        0,
                                        2
                                      )}:${selectedBooking.latestReturnCoreApiTrip.time.substring(
                                        2,
                                        4
                                      )}`
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            )}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={state.isReturnTripOpen}
                                  onChange={handleIsReturnTripOpenChanged}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{
                                label: classes.selectLabel,
                                root: classes.labelRoot
                              }}
                              label={<b>Open Return Trip</b>}
                            />
                            <br />
                            {!state.isReturnTripOpen && (
                              <GridContainer>
                                <GridItem xs={12}>
                                  <InputLabel className={classes.selectLabel}>
                                    <b>Return Date</b>
                                  </InputLabel>
                                  <FormControl fullWidth>
                                    <Datetime
                                      value={state.returnCoreApiTrip?.date}
                                      onChange={handleReturnDateChanged}
                                      dateFormat={"dddd, MMMM Do YYYY"}
                                      timeFormat={false}
                                      utc={true}
                                      closeOnSelect={true}
                                      inputProps={{
                                        placeholder: "Return Date"
                                      }}
                                    />
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor="selReturnRoute"
                                      className={classes.selectLabel}
                                    >
                                      <b>Return Route</b>
                                    </InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={
                                        state.returnCoreApiTrip.routeID === null
                                          ? ""
                                          : state.returnCoreApiTrip.routeID
                                      }
                                      onChange={handleReturnRouteIDChanged}
                                      inputProps={{
                                        name: "selReturnRoute",
                                        id: "selReturnRoute"
                                      }}
                                    >
                                      {returnRouteList.map(record => {
                                        return (
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected:
                                                classes.selectMenuItemSelected
                                            }}
                                            value={record.id}
                                            key={record.id}
                                          >
                                            {record.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor="selReturnTrip"
                                      className={classes.selectLabel}
                                    >
                                      <b>Return Time</b>
                                    </InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={
                                        state.returnCoreApiTrip?.time === null
                                          ? ""
                                          : state.returnCoreApiTrip.time
                                      }
                                      onChange={handleReturnTripChanged}
                                      inputProps={{
                                        name: "selReturnTrip",
                                        id: "selReturnTrip"
                                      }}
                                    >
                                      {returnScheduleList.map(record => {
                                        return (
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected:
                                                classes.selectMenuItemSelected
                                            }}
                                            value={record.tripTime}
                                            key={record.tripTime}
                                          >
                                            <p>
                                              <b>{`${record.tripTime.substring(
                                                0,
                                                2
                                              )}:${record.tripTime.substring(
                                                2,
                                                4
                                              )}`}</b>{" "}
                                              {record.timezoneLabel}
                                            </p>
                                            <b>{record.status}</b>
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </GridItem>
                              </GridContainer>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {selectedBooking !== null && (
        <ViewPassengerList id={selectedBooking.id} />
      )}
    </>
  );
}

Create.propTypes = {
  history: PropTypes.object
};
