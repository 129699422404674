import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { bookingApi } from "functions/apis";
import { token } from "functions/Helper";
// import ViewBooking from "./View";
import EditViewBooking from "./EditViewBooking";

export default function Edit(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [booking, setBooking] = React.useState(null);

  const loadPricing =
    token["booking.bookings.read_with_pricing"] === "allowed" ||
    token.is_admin === "True";

  useEffect(() => {
    if (loadPricing) {
      bookingApi
        .get(`/Bookings/${id}/WithPricing`, {
          signal: abortSignal.current.signal
        })
        .then(data => setBooking(data))
        .catch(error => console.log(error));
    } else {
      bookingApi
        .get(`/Bookings/${id}`, {
          signal: abortSignal.current.signal
        })
        .then(data => setBooking(data))
        .catch(error => console.log(error));
    }
  }, [id]);

  // https://api.test.sindoferry.com.sg/Agent/Booking/Bookings/{ID}
  return (
    booking !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <EditViewBooking
            booking={booking}
            history={props.history}
            isDisabled={false}
            id={id}
          />
        </GridItem>
      </GridContainer>
    )
  );
}

Edit.propTypes = {
  history: PropTypes.object
};
