import moment from "moment";

export function getTimezoneLabel(timezone) {
  switch (timezone) {
    case "Asia/Bangkok":
      return "Indo Time";
    case "Asia/Singapore":
      return "SG Time";
    default:
      return timezone;
  }
}

export function formatCurrency(currencyCode, number) {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode
  });
  return formatter.format(number);
}

export function formatDateTime(dateTime) {
  return moment(dateTime).format("YYYY MMM DD, h:mm:ss a");
}

function parseJWT(token) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

const token =
  localStorage.getItem("Authorization") !== null
    ? parseJWT(localStorage.getItem("Authorization").replace("Bearer ", ""))
    : {};

export { token };
