import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { bookingApi, creditMonitoringApi } from "functions/apis";
import { token } from "functions/Helper";
import View from "./View";

import DetailList from "./DetailList";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
const useStyles = makeStyles(styles);

export default function Submit(props) {
  const abortSignal = useRef(new AbortController());

  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [emailConfirmation, setEmailConfirmation] = React.useState(null);
  const [remarks, setRemarks] = React.useState(null);
  const [agentCredit, setAgentCredit] = React.useState(null);
  const [bookingTransfer, setBookingTransfer] = React.useState(null);

  useEffect(() => {
    if (
      token["creditmonitoring.agents.read"] === "allowed" ||
      token.is_admin === "True"
    )
      creditMonitoringApi
        .get("/Agents", {
          signal: abortSignal.current.signal
        })
        .then(data => setAgentCredit(data))
        .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    bookingApi
      .get("/BookingTransfers/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => setBookingTransfer(data))
      .catch(error => console.log(error));
  }, [id]);

  const handleEmailConfirmationChanged = e => {
    let trimmed = e.target.value.trim();
    setEmailConfirmation(trimmed === "" ? null : trimmed);
  };

  const handleRemarksChanged = e =>
    setRemarks(e.target.value === "" ? null : e.target.value);

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post(
        "/BookingTransfers/Submit",
        {
          id: id,
          emailConfirmation: emailConfirmation,
          remarks: remarks
        },
        {
          signal: abortSignal.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to="/admin/BookingTransfers/Submitted" />
  ) : (
    bookingTransfer !== null && agentCredit !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <View
            bookingTransfer={bookingTransfer}
            history={props.history}
            currency={
              agentCredit.billingCurrency === null
                ? ""
                : agentCredit.billingCurrency.code
            }
          />
          <DetailList bookingTransfer={bookingTransfer} />
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>SUBMIT BOOKING TRANSFER</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Currency"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          agentCredit.billingCurrency === null
                            ? ""
                            : agentCredit.billingCurrency.code
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Administration Fee"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: bookingTransfer.transferFee.toLocaleString()
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Outstanding Amount"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: bookingTransfer.outstandingAmount.toLocaleString()
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Pax(es) Transfer Fee"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: bookingTransfer.paxesTransferFee.toLocaleString()
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Total"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: bookingTransfer.total.toLocaleString()
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CustomInput
                  labelText="Email Confirmation"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleEmailConfirmationChanged,
                    value: emailConfirmation === null ? "" : emailConfirmation
                  }}
                />
                <CustomInput
                  labelText="Remarks"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleRemarksChanged,
                    value: remarks === null ? "" : remarks
                  }}
                />
                <Button onClick={() => props.history.goBack()} value="Cancel">
                  CANCEL
                </Button>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  SUBMIT
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

Submit.propTypes = {
  history: PropTypes.object
};
